import React, {FC} from 'react';

import {Container} from 'components';

import './DeliveryPolicy.scss';

const DeliveryPolicy: FC = () => {
  return (
    <Container className="TermsOfUse" maxWidth={720}>
      <p className="TermsOfUse__effective">生效时间: 01-01-2021</p>
      <p className="TermsOfUse__last-updated">上次更改时间: 11-28-2022</p>
      <h1 className="TermsOfUse__heading">发货/交付条款</h1>
      <p className="TermsOfUse__text">
        感谢您选择象寄服务。本交付政策概述了有关交付我们的软件即服务 （SaaS） 产品的条款和条件。
      </p>
      <div className="TermsOfUse__main-text" />
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">1. 数字交付</h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">我们的 SaaS 产品以数字方式即时交付，无需实际运输。</li>
          <li className="TermsOfUse__list-item">
            成功购买后，您将通过电子邮件或您的帐户仪表板收到有关访问和使用我们软件平台的说明。
          </li>
        </ul>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">2. 即时访问</h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">在您购买完成后，即可对我们的 SaaS 产品服务进行访问和使用。</li>
          <li className="TermsOfUse__list-item">在大多数情况下，您将能够在购买过程完成后立即开始使用我们的软件。</li>
        </ul>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">3. 账户设置</h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">要访问我们的 SaaS 产品，您需要按照提供的说明创建一个帐户。</li>
          <li className="TermsOfUse__list-item">请确保您在帐户设置过程中提供准确的信息。</li>
        </ul>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">4. 技术支持</h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">
            如果您在交付或设置过程中遇到任何问题，我们的技术支持团队可以为您提供帮助。
          </li>
          <li className="TermsOfUse__list-item">您可以通过最后的联系信息与我们的支持团队联系，以获得及时的帮助。</li>
        </ul>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">5. 联系方式</h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">
            如果您对我们的送货政策有任何疑问或疑虑，请通过最后的联系信息联系我们的客户支持团队。
          </li>
        </ul>
        <p className="TermsOfUse__text">
          我们保留随时更新或修改本送货政策的权利，恕不另行通知。任何更改将在我们的网站上发布后立即生效。您有责任定期查看本政策以获取任何更新。感谢您选择我们的SaaS产品。
        </p>
        <p className="TermsOfUse__text">电话号码: +8617664075341</p>
        <p className="TermsOfUse__text">支持邮箱: bussiness@tosoiot.com</p>
      </section>

      {/* <p className="TermsOfUse__effective">生效时间: 11/6/2023</p>
      <p className="TermsOfUse__last-updated">Last updated：11/6/2023</p>
      <h1 className="TermsOfUse__heading">Shipping/ Delivery Policy（发货/交付条款）</h1>
      <p className="TermsOfUse__text">
        Thank you for choosing XiangJi service. This Delivery Policy outlines the terms and conditions regarding the
        delivery of our software-as-a-service (SaaS) offering.
      </p>
      <div className="TermsOfUse__main-text" />
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">1. Digital Delivery:</h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">
            Our SaaS product is delivered digitally and immediately, eliminating the need for physical shipping.
          </li>
          <li className="TermsOfUse__list-item">
            Upon successful purchase, you will receive instructions on accessing and using our software platform via
            email or through your account dashboard.
          </li>
        </ul>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">2. Instant Access:</h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">
            We strive to provide instant access to our SaaS product upon purchase completion.
          </li>
          <li className="TermsOfUse__list-item">
            In most cases, you will be able to start using our software immediately after the purchase process is
            finalized.
          </li>
        </ul>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">3. Account Setup:</h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">
            To access our SaaS product, you will need to create an account using the provided instructions.
          </li>
          <li className="TermsOfUse__list-item">
            Please ensure that you provide accurate information during the account setup process.
          </li>
        </ul>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">4. Technical Support:</h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">
            If you encounter any issues during the delivery or setup process, our technical support team is available to
            assist you.
          </li>
          <li className="TermsOfUse__list-item">
            You can reach out to our support team via the contact info in the end for prompt assistance.
          </li>
        </ul>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">5. Contact Information:</h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">
            If you have any questions or concerns regarding our delivery policy, please contact our customer support
            team at the contact info in the end.
          </li>
        </ul>
        <p className="TermsOfUse__text">
          We reserve the right to update or modify this Delivery Policy at any time without prior notice. Any changes
          will be effective immediately upon posting on our website. It is your responsibility to review this policy
          periodically for any updates. Thank you for choosing our SaaS product.
        </p>
        <p className="TermsOfUse__text">phone number: +8617664075341</p>
        <p className="TermsOfUse__text">support email: bussiness@tosoiot.com</p>
      </section> */}
    </Container>
  );
};

export default DeliveryPolicy;
