import React, {ReactElement, ReactNode} from 'react';

import {PageTitle} from 'components';
import {ROUTES} from 'constants/routes';
// import {useHistory} from 'react-router-dom';
import DataTable from 'components/DisplayTable';
import {targetLan, engines} from './constants';

// import language from 'react-syntax-highlighter/dist/esm/languages/hljs/1c';
import Hero from './Hero';
import Section from './Section';

import * as S from './Styles';

const Roadmap: React.FC = () => {
  // const history = useHistory();

  const columnsSour = [<div>语种</div>, <div>示例</div>];
  const columnsTar = [
    <div style={{width: '100%'}}>语种</div>,
    <div>示例</div>,
    <div>谷歌</div>,
    <div>Papago</div>,
    <div>DeepL</div>,
    <div>阿里云</div>,
  ];
  const sourceTableData = [
    [<span>中文</span>, <span>今天天气不错</span>],
    [<span>英文</span>, <span>the weather is nice today！</span>],
    [<span>日语</span>, <span>今日は良い天気</span>],
    [<span>中文繁体</span>, <span>今天天氣不錯！</span>],
  ];
  const renderShowcase = (): ReactElement => {
    return (
      <S.Showcase>
        {engines.map((engine) => (
          <S.App
            key={engine.key}
            role="button"
            tabIndex={0}
            onClick={() => {
              window.open(engine.url);
            }}
          >
            <S.AppImage src={engine.thumbnail} alt={engine.name} />
          </S.App>
        ))}
      </S.Showcase>
    );
  };
  const renderMark = (isSupported: any): ReactElement => {
    if (isSupported)
      return (
        <div style={{color: 'green', fontSize: '20px'}}>
          <span role="img" aria-label="Snowman">
            &#10004;
          </span>
        </div>
      );
    return (
      <div>
        <span role="img" aria-label="Snowman">
          &#10060;
        </span>
      </div>
    );
  };
  const renderTargetLanData = (): ReactNode[][] => {
    return targetLan.map((lan) => [
      <span style={{width: '50%'}}>{lan.name}</span>,
      <span>{lan.example}</span>,
      renderMark(lan.google),
      renderMark(lan.papago),
      renderMark(lan.deepl),
      renderMark(lan.ali),
    ]);
  };
  return (
    <>
      <PageTitle title="支持语言" />

      <Hero />

      <S.Container>
        <Section title="文本引擎">
          <S.Paragraph mb={32}>
            象寄图片翻译在将源语言文字翻译为目标语言文字过程中，使用第三方文本翻译引擎，为了提高翻译准确度，象寄现已经接入了
            <S.Highlight>Papago(中韩翻译推荐)</S.Highlight>，<S.Highlight>谷歌翻译（英文拉丁语系等推荐使用</S.Highlight>
            ), <S.Highlight>阿里翻译（电商版本）</S.Highlight>，
            <S.Highlight>DeepL（英文俄语小语种等推荐使用）</S.Highlight>
            ，用户可以根据需要自行选择。如有其他文本翻译引擎接入的需求，欢迎{' '}
            <S.Link to={ROUTES.contact}>联系我们</S.Link>。
          </S.Paragraph>
          {renderShowcase()}
          {/* <S.List bold>
            <S.ListItem>Beta Blockchain Infrastructure</S.ListItem>
            <S.ListItem>Wallet+</S.ListItem>
            <S.ListItem>Token System</S.ListItem>
            <S.ListItem>NFT Marketplace</S.ListItem>
            <S.ListItem mb={0}>Bridge</S.ListItem>
          </S.List> */}
        </Section>

        <Section title="可以识别的文本语种（源语言）">
          <S.Paragraph>
            象寄目前支持下图四种源语言：
            {/* <S.A newWindow={false} href={URLS.developerPortal.whitepaper}>
              Living Whitepaper
            </S.A>
            and it is the most important technological product for 2022. The rest of our roadmap depends on it. */}
          </S.Paragraph>
          <S.SourceContainer>
            <DataTable columns={columnsSour} data={sourceTableData} />
          </S.SourceContainer>
        </Section>

        <Section title="可以翻译为的语种（目标语言）">
          <S.Paragraph mb={16}>
            总体而言，象寄已支持大部分电商平台对应语种，但并不是所有象寄支持的翻译引擎都支持这些语种。以”简体中文“为源语言，以下表格列出了各个文本引擎对语言的支持情况。
          </S.Paragraph>
          {/* <S.List mb={16}>
            <S.ListItem>
              Vote community members into governance to decentralize our key decision processes by distributing
              decision-making power to the entire community and its most trusted members
            </S.ListItem>
            <S.ListItem>Boost nodes to determine Top 20 nodes</S.ListItem>
            <S.ListItem>Deposit coins to earn points</S.ListItem>
            <S.ListItem>Have visibility in the treasury to monitor how coins are being distributed</S.ListItem>
          </S.List>
          <S.Paragraph>
            Besides our beta blockchain, Wallet+ is currently the highest-priority product on the roadmap because the
            Token System, Escrow, and Exchange rely on it for using our blockchain.{' '}
            <S.Link to={ROUTES.walletProgress}>View Wallet+ progress</S.Link>.
          </S.Paragraph> */}
          <S.TargetContainer>
            <DataTable columns={columnsTar} data={renderTargetLanData()} />
          </S.TargetContainer>
        </Section>
      </S.Container>
    </>
  );
};

export default Roadmap;
