import styled, {css} from 'styled-components';
import {Link as RouterLink} from 'react-router-dom';

import colors from 'styles/colors';
import {b1} from 'styles/fonts';

import {A as BaseA} from 'components';

type CommonProps = {
  mb?: number;
};

type ListProps = CommonProps & {
  bold?: boolean;
};
const THUMBNAIL_MARGIN = {
  default: '8px',
  lg: '8px',
  md: '8px',
  sm: '6px',
};

const THUMBNAIL_SIZE = {
  default: '170px',
  lg: '170px',
  md: '160px',
  sm: '108px',
};

const THUMBNAIL_BORDER_RADIUS = '16px';

export const Container = styled.div`
  padding: 80px 0px;
  margin: 0 auto;
  max-width: 888px;

  @media (max-width: 768px) {
    padding: 48px 24px;
  }
`;

export const Paragraph = styled.p<CommonProps>`
  ${b1.regular};
  color: ${colors.palette.neutral['500']};
  line-height: 20px;
  margin: 0;

  ${({mb}) =>
    !!mb &&
    css`
      margin-bottom: ${mb}px;
    `}
`;

export const Highlight = styled.span`
  ${b1.medium};
  color: ${colors.palette.neutral['600']};
`;

export const List = styled.ul<ListProps>`
  ${b1.medium};
  color: ${colors.palette.neutral['600']};
  padding-inline-start: 20px;

  ${({mb}) =>
    !!mb &&
    css`
      margin-bottom: ${mb}px;
    `}

  ${({bold}) =>
    !bold &&
    css`
      ${b1.regular};
      color: ${colors.palette.neutral['500']};
    `}
`;

export const ListItem = styled.li<CommonProps>`
  ${({mb = 8}) =>
    css`
      margin-bottom: ${mb}px;
    `}
`;

export const A = styled(BaseA)`
  ${b1.medium};
  color: ${colors.quaternary};
`;

export const Link = styled(RouterLink)`
  ${b1.medium};
  color: ${colors.quaternary};
`;
export const Showcase = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // max-width: calc((${THUMBNAIL_SIZE.default} * 5) + (${THUMBNAIL_MARGIN.default} * 10));

  @media (max-width: 1366px) {
    // max-width: calc((${THUMBNAIL_SIZE.lg} * 4) + (${THUMBNAIL_MARGIN.lg} * 8));
  }

  @media (max-width: 992px) {
    // max-width: calc((${THUMBNAIL_SIZE.md} * 4) + (${THUMBNAIL_MARGIN.md} * 8));
  }

  @media (max-width: 768px) {
    // max-width: calc((${THUMBNAIL_SIZE.sm} * 3) + (${THUMBNAIL_MARGIN.sm} * 6));
  }
`;

export const App = styled.div`
  border-radius: ${THUMBNAIL_BORDER_RADIUS};
  cursor: pointer;
  filter: drop-shadow(0 4px 6px rgba(26, 41, 54, 0.5));
  height: ${THUMBNAIL_SIZE.default};
  margin: ${THUMBNAIL_MARGIN.default};
  transition: transform 0.3s ease;
  width: ${THUMBNAIL_SIZE.default};

  &:hover {
    transform: scale(1.03);
  }

  @media (max-width: 1366px) {
    height: ${THUMBNAIL_SIZE.lg};
    margin: ${THUMBNAIL_MARGIN.lg};
    width: ${THUMBNAIL_SIZE.lg};
  }

  @media (max-width: 992px) {
    height: ${THUMBNAIL_SIZE.md};
    margin: ${THUMBNAIL_MARGIN.md};
    width: ${THUMBNAIL_SIZE.md};
  }

  @media (max-width: 768px) {
    height: ${THUMBNAIL_SIZE.sm};
    margin: ${THUMBNAIL_MARGIN.sm};
    width: ${THUMBNAIL_SIZE.sm};
  }
`;

export const AppImage = styled.img`
  border-radius: ${THUMBNAIL_BORDER_RADIUS};
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const SourceContainer = styled.div`
  border-radius: ${THUMBNAIL_BORDER_RADIUS};
  height: 100%;
  object-fit: cover;
  width: 100%;
  padding: 50px;
  margin: auto;
  table {
    margin: auto;
  }
`;

export const TargetContainer = styled.div`
  border-radius: ${THUMBNAIL_BORDER_RADIUS};
  height: 100%;
  object-fit: cover;
  width: 100%;
  padding: 50px;
  margin: auto;
  table {
    width: 100%;
    // table-layout: auto;
    th:nth-child(1) {
      width: 200px;
    }
    th:nth-child(2) {
      width: 200px;
    }
  }
`;
export const CheckMark = styled.div`
  position: relative;
  display: inline-block;
  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 20px;
    border: solid #090;
    border-width: 0 8px 8px 0;
    transform: rotate(45deg);
  }
`;
export const CrossMark = styled.div`
  // width: 90%;
  margin-right: 30px;
  position: relative;
  display: inline-block;
  &:after {
    position: absolute;
    content: '';
    display: block;
    width: 30px;
    height: 0px;
    border: solid #e00;
    border-width: 0 0px 8px 0;
    transform: rotate(45deg);
    left: 0;
    top: 0;
  }
  &:before {
    position: absolute;
    content: '';
    display: block;
    width: 30px;
    height: 0px;
    border: solid #e00;
    border-width: 0 0px 8px 0;
    transform: rotate(-45deg);
    left: 0;
    top: 0;
  }
`;
