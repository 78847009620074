import React, {FC} from 'react';

import {CustomIconProps} from './types';

const CommunityIcon: FC<CustomIconProps> = ({size = 24, state = 'default'}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#faqCommunityPaintClip0)">
        <path
          d="M28.1538 27.6166C27.3217 28.4598 26.5771 28.9554 26.007 29.3296C20.4474 32.9788 14.0921 31.927 12.1272 31.5675C8.95711 30.9875 6.28116 30.5783 3.98189 28.5363C-1.24253 23.8965 -0.988403 14.2171 3.00368 8.22122C3.47707 7.51032 7.46309 1.70197 13.8321 0.883519C13.9879 0.863516 14.104 0.852053 14.2059 0.84149C15.1912 0.738889 21.2967 0.197678 26.2752 4.29789C26.9878 4.88484 28.1347 5.94344 29.1982 7.55808C33.0868 13.462 33.0991 22.6056 28.1538 27.6166Z"
          fill="url(#faqCommunityPaint0_linear)"
        />
        <path
          d="M19.0965 9.37666C19.3066 9.62136 19.5578 9.83481 19.8441 10.0052L19.8581 10.6917C19.8596 10.7679 19.9255 10.7385 20.6917 10.9973C20.7626 11.0199 20.7547 10.9545 21.1723 10.4284C21.5054 10.4554 21.8353 10.4272 22.1506 10.3498L22.6522 10.8259C22.708 10.8787 22.7333 10.8118 23.4603 10.4596C23.482 10.4487 23.4942 10.4252 23.4908 10.4015L23.3929 9.72119C23.6405 9.51361 23.8566 9.26543 24.029 8.98254C24.6928 8.95563 24.7538 9.00099 24.7771 8.92958C25.0169 8.14839 25.0731 8.12712 25.0131 8.08263L24.4573 7.67027C24.4847 7.34115 24.4561 7.01507 24.3778 6.70363L24.8596 6.20805C24.9131 6.15314 24.8453 6.12785 24.4889 5.4096C24.4552 5.34438 24.4167 5.39549 23.7415 5.47623C23.5314 5.23153 23.2803 5.01808 22.9939 4.84771L22.9799 4.16114C22.9784 4.08486 22.9125 4.11437 22.1463 3.85557C22.0758 3.8331 22.0854 3.89572 21.6658 4.42462C21.3327 4.3976 21.0027 4.42581 20.6875 4.50318L20.1859 4.02713C20.1683 4.01042 20.142 4.00662 20.1202 4.01758L19.3778 4.39336C19.3561 4.40432 19.3438 4.42776 19.3473 4.45153L19.4452 5.1318C19.1976 5.33939 18.9815 5.58745 18.8091 5.87046C18.1453 5.89737 18.0842 5.85201 18.061 5.92341C17.8213 6.70428 17.7649 6.72577 17.8249 6.77037L18.3808 7.18272C18.3534 7.51184 18.382 7.83793 18.4603 8.14937C18.0101 8.63214 17.9341 8.64278 17.9688 8.70973L18.3491 9.44329C18.3831 9.50894 18.4248 9.45707 19.0965 9.37666Z"
          fill="#91B3FA"
        />
        <path
          d="M20.5507 7.37092C20.5819 6.8966 20.9955 6.53753 21.4756 6.56824C21.9556 6.59895 22.3191 7.00772 22.288 7.48204C22.257 7.95527 21.842 8.31532 21.3631 8.28472C20.884 8.25411 20.5197 7.84426 20.5507 7.37092ZM21.3714 8.15689L21.3722 8.15721L21.3714 8.15689ZM20.8095 7.38741C20.7878 7.71968 21.0437 8.00746 21.3798 8.02895C21.9197 8.06346 22.2367 7.43114 21.8784 7.02812C21.5217 6.62694 20.8448 6.85059 20.8095 7.38741Z"
          fill="#BDD0FB"
        />
        <path
          d="M17.0504 8.71309C17.0169 8.60241 16.9875 8.4889 16.9629 8.37572C16.9529 8.3296 16.9825 8.28414 17.0292 8.27426C17.1467 8.2493 17.13 8.37898 17.2161 8.66426C17.2299 8.70951 17.2037 8.75704 17.158 8.77061C17.1125 8.78406 17.0641 8.75856 17.0504 8.71309Z"
          fill="#91B3FA"
        />
        <path
          d="M16.8653 7.23754C16.875 7.01085 16.9024 6.77483 16.9501 6.53871C16.9725 6.42759 17.1421 6.46166 17.1197 6.57224C16.9959 7.18404 17.0926 7.3327 16.9479 7.32663C16.8999 7.32446 16.8632 7.28441 16.8653 7.23754ZM17.359 5.57022C17.3161 5.5496 17.2982 5.49838 17.319 5.45595C17.4227 5.24554 17.5442 5.04132 17.6801 4.84903C17.7457 4.75647 17.8875 4.85424 17.8219 4.94691C17.6911 5.13182 17.5743 5.32823 17.4745 5.53072C17.4379 5.60527 17.3486 5.57 17.359 5.57022ZM18.4765 4.14586C18.4655 4.14087 18.4554 4.13349 18.4469 4.12383C18.4159 4.08791 18.4202 4.03409 18.4564 4.00327C18.6366 3.85092 18.8302 3.71137 19.032 3.58864C19.0726 3.5639 19.126 3.57649 19.1509 3.61653C19.176 3.65668 19.1634 3.70942 19.1227 3.73405C18.9287 3.85222 18.7423 3.98645 18.5689 4.13306C18.5425 4.15552 18.5063 4.15932 18.4765 4.14586ZM20.074 3.27731C20.0524 3.26744 20.0351 3.24888 20.0275 3.22468C20.0134 3.17954 20.0391 3.1318 20.0848 3.11801C20.3115 3.04943 20.5454 2.998 20.7803 2.96544C20.8277 2.95893 20.8714 2.99149 20.8781 3.03826C20.8847 3.08492 20.8517 3.12821 20.8044 3.13473C20.5785 3.16609 20.3534 3.21546 20.1354 3.28144C20.1145 3.28784 20.0927 3.28578 20.074 3.27731ZM21.8403 3.02599C21.8457 2.97912 21.8885 2.94537 21.936 2.95069C22.1723 2.97716 22.4077 3.02198 22.6356 3.08405C22.7456 3.11346 22.7002 3.27883 22.5895 3.24877C22.3703 3.18909 22.1439 3.1459 21.9164 3.12051C21.868 3.11487 21.8351 3.0719 21.8403 3.02599Z"
          fill="#91B3FA"
        />
        <path
          d="M23.905 3.85486C23.6437 3.67701 23.5286 3.65867 23.5872 3.55884C23.6112 3.51804 23.6632 3.50339 23.7054 3.5278C23.8062 3.58553 23.9064 3.64825 24.0032 3.71412C24.0425 3.74092 24.0524 3.7941 24.0252 3.83294C23.9985 3.87125 23.9452 3.88199 23.905 3.85486Z"
          fill="#91B3FA"
        />
        <path
          d="M25.7698 6.87314C25.7294 6.56105 25.6666 6.46502 25.7809 6.44158C25.8277 6.43192 25.8735 6.46165 25.8832 6.50788C25.9069 6.62063 25.9264 6.73619 25.9414 6.85144C25.9474 6.89821 25.9139 6.94107 25.8666 6.94704C25.8194 6.95311 25.7759 6.92002 25.7698 6.87314Z"
          fill="#91B3FA"
        />
        <path
          d="M19.9283 11.5936C19.9734 11.4589 20.0756 11.5977 20.6919 11.6993C20.8045 11.7177 20.7766 11.8865 20.6634 11.868C20.433 11.83 20.2041 11.774 19.9832 11.7017C19.9386 11.6872 19.913 11.639 19.9283 11.5936ZM21.7057 11.8367C21.7019 11.7897 21.7372 11.7484 21.7849 11.7446C22.4129 11.6936 22.5206 11.5635 22.5546 11.7019C22.5658 11.7478 22.5372 11.794 22.4908 11.805C22.2643 11.8591 22.0316 11.8961 21.799 11.9148C21.7514 11.9187 21.7097 11.8838 21.7057 11.8367ZM23.4365 11.3713C23.369 11.2455 23.5397 11.2696 24.0417 10.8994C24.1334 10.8319 24.237 10.9687 24.1452 11.0363C23.9582 11.1742 23.759 11.2989 23.5535 11.4067C23.5113 11.4288 23.4589 11.4131 23.4365 11.3713ZM24.843 10.2707C24.7309 10.1809 24.8982 10.1372 25.2131 9.60229C25.2705 9.50463 25.4202 9.59036 25.3626 9.68824C25.2455 9.88725 25.1117 10.0789 24.9647 10.2582C24.9345 10.2948 24.8802 10.3004 24.843 10.2707ZM25.6401 8.60754C25.8112 8.00887 25.7256 7.8627 25.8691 7.88007C25.9165 7.88571 25.9502 7.92835 25.9445 7.97523C25.9162 8.20463 25.8698 8.43305 25.8066 8.6541C25.7936 8.69946 25.7458 8.72593 25.6998 8.71313C25.6539 8.70011 25.627 8.65301 25.6401 8.60754Z"
          fill="#91B3FA"
        />
        <path
          d="M18.9537 11.215C18.8553 11.1523 18.7581 11.0851 18.665 11.0152C18.627 10.9867 18.6196 10.9331 18.6485 10.8954C18.7213 10.8008 18.7935 10.9096 19.0474 11.0712C19.0874 11.0968 19.0991 11.1497 19.0732 11.1894C19.047 11.2297 18.9931 11.2402 18.9537 11.215Z"
          fill="#91B3FA"
        />
        <path
          d="M20.364 9.25187C20.2927 9.21161 20.2784 9.13207 20.3122 9.07423C20.3481 9.01292 20.428 8.9922 20.4894 9.02746C20.6045 9.09322 20.7279 9.14737 20.856 9.18828C20.8841 9.19165 21.5432 9.45154 22.2927 9.07348C23.779 8.32104 23.6034 6.15325 22.0121 5.64649C21.0967 5.3548 20.1168 5.79504 19.7241 6.63766C19.3283 7.48624 19.7757 7.92767 19.5019 7.99029C19.4321 8.00635 19.3624 7.96337 19.3461 7.89447C19.2587 7.52248 19.2755 7.13292 19.3948 6.76766C19.7566 5.65853 20.9655 5.0438 22.0917 5.40255C23.3035 5.78853 23.9219 7.14214 23.3784 8.30736C22.9402 9.24721 21.838 9.77057 20.7763 9.43222C20.6328 9.38632 20.4939 9.32566 20.364 9.25187Z"
          fill="#BDD0FB"
        />
        <path
          d="M11.687 29.5963C11.687 29.5963 11.6147 29.9795 11.5774 30.3592C11.5628 30.5076 11.6775 30.6377 11.8282 30.6453C12.3402 30.6712 13.4353 30.7225 13.8286 30.7088C13.8877 30.7068 13.9269 30.6474 13.9056 30.5928C13.8877 30.5472 13.8527 30.5101 13.8078 30.4891L12.0061 29.6503L11.687 29.5963Z"
          fill="url(#faqCommunityPaint1_linear)"
        />
        <path
          d="M11.6328 29.3702L11.6836 29.8299C11.6836 29.8299 11.9992 29.9787 12.2172 29.8165L12.0909 29.3036L11.6328 29.3702Z"
          fill="url(#faqCommunityPaint2_linear)"
        />
        <path
          d="M11.7579 20.7381C11.733 20.8578 11.5723 26.7207 12.1813 29.3546C12.1912 29.3975 12.1688 29.4415 12.1273 29.4578C12.0297 29.4961 11.8384 29.5512 11.6097 29.5566C11.5389 29.5583 11.4759 29.5122 11.4565 29.445C11.2811 28.8336 10.4416 25.7707 10.3565 22.8514C10.2613 19.5895 10.3576 22.7404 10.3576 22.7404L10.4514 20.7255L11.7579 20.7381Z"
          fill="url(#faqCommunityPaint3_linear)"
        />
        <path
          d="M9.60341 29.777C9.60341 29.777 9.49226 29.9697 9.38155 30.4837C9.36156 30.5765 9.42647 30.6661 9.52191 30.6763C9.69083 30.6943 9.95101 30.7179 10.1596 30.7185C10.2557 30.7188 10.3316 30.6368 10.3209 30.5424C10.2996 30.3543 10.2306 30.0452 10.0106 29.8027L9.60341 29.777Z"
          fill="url(#faqCommunityPaint4_linear)"
        />
        <path
          d="M9.5127 29.1854L9.63482 29.881C9.63482 29.881 9.76134 29.9759 10.0139 29.9103L9.95969 29.1627L9.5127 29.1854Z"
          fill="url(#faqCommunityPaint5_linear)"
        />
        <path
          d="M9.24472 20.8943C8.69603 23.9832 9.28338 28.542 9.48777 29.4351C9.50029 29.4898 9.54444 29.532 9.60034 29.5417C9.69523 29.5582 9.85163 29.5723 10.0064 29.5295C10.0596 29.5148 10.0975 29.4678 10.1002 29.4131C10.1356 28.6929 10.127 24.2697 10.6273 22.5263C10.6527 22.438 10.7082 22.3613 10.7845 22.3088L11.7292 21.6586L11.8115 20.5938L9.24472 20.8943Z"
          fill="url(#faqCommunityPaint6_linear)"
        />
        <path
          d="M12.6072 15.0632C11.0526 15.0231 9.3328 15.4357 9.3328 15.4357C8.84352 18.1034 9.19618 21.189 9.19618 21.189C10.3969 21.5123 11.7597 21.0225 11.7597 21.0225C11.9705 19.9075 12.9344 16.773 12.9344 16.773C13.6949 16.6871 14.3229 16.4764 14.8411 16.1949C14.4523 15.741 14.2725 15.1663 14.1953 14.8268C13.6439 14.9906 13.0867 15.0756 12.6072 15.0632Z"
          fill="url(#faqCommunityPaint7_linear)"
        />
        <path
          d="M17.2221 12.8436C17.071 12.6599 16.8769 12.7443 16.8769 12.7443C16.6389 13.6972 15.4315 14.4602 14.1953 14.8271C14.2725 15.1665 14.4523 15.7412 14.8411 16.1951C16.8977 15.0779 17.2221 12.8436 17.2221 12.8436Z"
          fill="url(#faqCommunityPaint8_linear)"
        />
        <path
          d="M17.1849 12.8896C17.2004 12.94 17.0146 13.0146 16.9076 12.8084C16.9047 12.7099 16.8483 12.2878 16.9086 12.2082C17.2296 11.7842 17.8494 11.2548 17.8494 11.2548C17.9523 11.357 17.8657 11.4556 17.8657 11.4556L17.5647 11.7597C17.626 11.8716 17.7011 11.9663 17.7355 12.1013C17.7615 12.2035 17.7439 12.3123 17.6909 12.4047C17.6376 12.4974 17.5651 12.6177 17.4967 12.7119C17.3671 12.89 17.1849 12.8896 17.1849 12.8896Z"
          fill="url(#faqCommunityPaint9_linear)"
        />
        <path
          d="M17.4327 11.9575L17.1588 12.1825L17.0713 12.4082L17.3109 12.676C17.3109 12.676 17.3967 12.2273 17.4582 12.1305C17.5198 12.0339 17.4888 11.9865 17.4327 11.9575Z"
          fill="url(#faqCommunityPaint10_linear)"
        />
        <path
          d="M9.91561 16.9289L9.33363 15.4358C9.33363 15.4358 7.87183 16.3971 7.62109 17.631C7.89533 17.6536 8.39395 17.7288 8.88048 17.9773C8.99339 17.3946 9.91561 16.9289 9.91561 16.9289Z"
          fill="url(#faqCommunityPaint11_linear)"
        />
        <path
          d="M11.1708 18.3407C11.1708 18.3407 9.53142 19.2359 8.98865 18.4533C8.87597 18.2907 8.85027 18.13 8.87981 17.9773C8.39339 17.7288 7.89466 17.6536 7.62042 17.631C7.54486 18.003 7.57902 18.3998 7.79681 18.8025C8.74374 20.5534 11.3827 18.7399 11.3827 18.7399L11.1708 18.3407Z"
          fill="url(#faqCommunityPaint12_linear)"
        />
        <path
          d="M11.0446 13.8011L10.5363 15.2101C10.5278 15.2334 10.5374 15.2595 10.5592 15.2716C10.6933 15.3466 11.1772 15.5641 11.7662 15.2028C11.8276 15.1652 11.8697 15.1032 11.8797 15.0326L12.0517 13.8106L11.0446 13.8011Z"
          fill="url(#faqCommunityPaint13_linear)"
        />
        <path
          d="M12.134 12.5022C12.134 12.5022 12.6675 13.5178 12.594 13.9253C12.5204 14.3327 11.8474 14.2971 11.2626 13.9601L10.8623 12.8652L12.134 12.5022Z"
          fill="url(#faqCommunityPaint14_linear)"
        />
        <path
          d="M11.2933 13.4245C11.3172 13.4143 11.3263 13.3855 11.3123 13.3638C11.2693 13.2977 11.189 13.1629 11.1642 13.0453C11.1642 13.0453 11.0671 12.9826 11.4142 12.9039C11.7612 12.8252 12.2787 12.703 12.3901 12.4129C12.4986 12.13 12.2276 11.4133 11.7785 11.978C11.7613 11.9995 11.7283 11.9997 11.7117 11.9778C11.6399 11.8836 11.4506 11.6878 11.1841 11.8029C10.9347 11.9106 10.9049 12.0902 10.9074 12.1809C10.9082 12.2075 10.8838 12.2281 10.8574 12.2227C10.732 12.1979 10.4309 12.1748 10.2872 12.4788C10.169 12.7293 10.2429 12.8724 10.3107 12.9427C10.3327 12.9655 10.3226 13.0026 10.2921 13.0115C10.2093 13.0357 10.1021 13.1039 10.1109 13.295C10.123 13.5592 10.4221 13.5388 10.5073 13.5275C10.5213 13.5257 10.5353 13.5305 10.5447 13.541C10.6168 13.6214 10.9424 13.9632 11.2977 14.0075C11.3284 14.0113 11.3526 13.9814 11.3428 13.9523L11.1931 13.5086C11.1862 13.488 11.1963 13.4655 11.2165 13.457L11.2933 13.4245Z"
          fill="url(#faqCommunityPaint15_linear)"
        />
        <path
          d="M11.3549 13.4879C11.3549 13.4879 10.9706 13.222 10.9402 13.4425C10.9098 13.663 11.2854 13.7657 11.4728 13.6851L11.3549 13.4879Z"
          fill="url(#faqCommunityPaint16_linear)"
        />
        <path
          d="M10.8356 19.7555L11.7286 16.674C11.7387 16.639 11.7689 16.6135 11.8055 16.6093C11.9673 16.5906 12.4361 16.5382 12.9774 16.4931C13.6295 16.4386 14.3868 16.3945 14.8392 16.432C14.9036 16.4373 14.9425 16.5046 14.9138 16.5618C14.6565 17.0761 13.5981 19.1685 13.0681 19.8539C13.0505 19.8766 13.0234 19.8899 12.9944 19.8896L10.9224 19.8705C10.8623 19.8698 10.819 19.8128 10.8356 19.7555Z"
          fill="url(#faqCommunityPaint17_linear)"
        />
        <path
          d="M11.051 19.011C11.051 19.011 10.9266 18.5055 10.9768 18.3262C11.027 18.1469 11.1242 17.9164 11.4483 17.9676C11.7724 18.0188 12.1547 18.1537 12.1547 18.1537C12.1547 18.1537 12.1353 18.3872 11.889 18.3119L12.0418 18.4287C12.0418 18.4287 12.0199 18.6156 11.8119 18.5158L11.9539 18.6707C11.9539 18.6707 11.8645 18.8242 11.7115 18.7258C11.5585 18.6272 11.485 19.0718 11.051 19.011Z"
          fill="url(#faqCommunityPaint18_linear)"
        />
        <path
          d="M12.918 17.4129C12.918 17.5559 13.0354 17.6719 13.1801 17.6719C13.3249 17.6719 13.4423 17.5559 13.4423 17.4129C13.4423 17.2699 13.3249 17.1539 13.1801 17.1539C13.0353 17.1539 12.918 17.2699 12.918 17.4129Z"
          fill="#91B3FA"
        />
        <path
          d="M11.8296 17.5837L11.7627 17.5642L11.9445 16.9548C11.9741 16.8555 12.0552 16.7809 12.1561 16.7602C12.7325 16.6413 14.2504 16.6171 14.3146 16.6161L14.3157 16.685C14.3 16.6852 12.7402 16.7102 12.1704 16.8276C12.0947 16.8432 12.0337 16.8993 12.0113 16.9742L11.8296 17.5837Z"
          fill="#91B3FA"
        />
        <path
          d="M1.26146 26.2072C1.16624 26.3464 0.787887 29.4977 0.72869 30.6616C0.724736 30.7403 0.788437 30.806 0.868171 30.806C0.936924 30.806 0.995462 30.7565 1.00611 30.6893L1.69814 26.3075L1.26146 26.2072Z"
          fill="#7D97F4"
        />
        <path
          d="M4.30289 26.2072C4.39811 26.3464 4.77646 29.4977 4.83566 30.6616C4.83972 30.7403 4.77591 30.806 4.69618 30.806C4.62743 30.806 4.56889 30.7565 4.55824 30.6893L3.86621 26.3075L4.30289 26.2072Z"
          fill="#7D97F4"
        />
        <path
          d="M0.688824 25.9614L0.688495 25.9958C0.686189 26.243 0.90178 26.4458 1.17174 26.4503L4.37046 26.504C4.63646 26.5085 4.85821 26.3186 4.87116 26.0752L4.87281 26.0437C4.88632 25.7899 4.66809 25.576 4.39067 25.5711L1.19052 25.5145C0.916607 25.5096 0.69113 25.7105 0.688824 25.9614Z"
          fill="#7D97F4"
        />
        <path
          d="M7.42788 30.5472C6.85931 30.5472 6.60769 30.499 6.49512 30.4551C6.51697 30.5473 6.60011 30.6201 6.70522 30.6201H8.71681C8.78458 30.6201 8.8273 30.5561 8.81116 30.498C8.58195 30.5163 8.09486 30.5472 7.42788 30.5472Z"
          fill="#91B3FA"
        />
        <path
          d="M8.81115 30.498C8.80785 30.4863 8.80313 30.4748 8.79467 30.4642C8.64146 30.2709 8.33362 29.9673 7.91484 29.9732C7.30146 29.9817 7.08751 30.0474 6.61086 29.9769L6.4984 30.3451C6.48697 30.3827 6.48675 30.4202 6.49499 30.455C6.60745 30.4989 6.85907 30.5471 7.42776 30.5471C8.09485 30.5472 8.58194 30.5163 8.81115 30.498Z"
          fill="url(#faqCommunityPaint19_linear)"
        />
        <path
          d="M6.7484 29.58L6.61035 30.0439C6.61035 30.0439 6.91611 30.1815 7.18508 30.071L7.23791 29.6692L6.7484 29.58Z"
          fill="url(#faqCommunityPaint20_linear)"
        />
        <path
          d="M10.9181 30.256C10.4748 30.6079 10.2482 30.7262 10.1328 30.7617C10.208 30.8197 10.3189 30.8247 10.4008 30.7597L11.9689 29.5149C12.0217 29.473 12.0146 29.3968 11.9654 29.3618C11.7981 29.5179 11.438 29.8432 10.9181 30.256Z"
          fill="#91B3FA"
        />
        <path
          d="M11.9658 29.3609C11.9559 29.3538 11.9448 29.3478 11.9316 29.3448C11.6902 29.2897 11.2587 29.2446 10.936 29.5083C10.4632 29.8945 10.338 30.0779 9.92188 30.3182L10.0665 30.6736C10.0812 30.7098 10.1047 30.739 10.1332 30.7609C10.2486 30.7254 10.4752 30.6072 10.9185 30.2552C11.4384 29.8422 11.7985 29.5169 11.9658 29.3609Z"
          fill="url(#faqCommunityPaint21_linear)"
        />
        <path
          d="M3.79586 24.2952C3.79586 24.2952 7.20029 24.5698 7.89868 24.8946C8.59708 25.2194 7.32879 29.7722 7.32879 29.7722C7.32879 29.7722 7.13868 29.8626 6.71562 29.7327C6.71562 29.7327 7.14944 26.373 6.82666 25.6444L3.14062 25.0487L3.79586 24.2952Z"
          fill="url(#faqCommunityPaint22_linear)"
        />
        <path
          d="M9.58301 29.6786C9.61464 29.7071 9.92128 30.3181 9.92128 30.3181C9.92128 30.3181 10.2626 30.2452 10.3323 30.0316L10.0251 29.4802L9.58301 29.6786Z"
          fill="url(#faqCommunityPaint23_linear)"
        />
        <path
          d="M1.74523 24.2671C1.74523 24.2671 1.6343 24.5309 1.63881 24.8814C1.64507 25.3661 2.03946 25.7595 2.52951 25.7837L6.55403 25.965C6.63904 25.9688 6.71098 26.027 6.73217 26.1084C6.82673 26.4698 7.289 27.5797 9.5487 29.7945C9.60878 29.8534 9.70246 29.8634 9.77407 29.8188L10.0958 29.618C10.1295 29.597 10.1403 29.5533 10.1201 29.5192L7.77685 25.5655C7.65374 25.3648 7.45594 25.2198 7.22585 25.1617L3.79571 24.2953L1.74523 24.2671Z"
          fill="url(#faqCommunityPaint24_linear)"
        />
        <path
          d="M5.71153 18.0911C5.71153 18.0911 5.28046 18.8443 4.84082 19.2091L5.74942 19.71C5.74942 19.71 5.95414 18.9126 6.42189 18.6792L5.71153 18.0911Z"
          fill="url(#faqCommunityPaint25_linear)"
        />
        <path
          d="M1.67188 24.2951C1.67188 24.2951 2.7584 24.5568 3.79539 24.2951C3.79539 24.2951 5.24654 22.3677 5.80787 21.8432C5.80787 21.8432 6.70341 23.2393 8.69656 23.4372L8.8077 23.1918C8.8077 23.1918 7.22092 22.4691 6.7324 21.3585C6.24389 20.248 6.04774 19.7745 5.60326 19.4846C5.3265 19.3042 5.16077 19.1809 5.0751 19.1136C5.02821 19.0767 4.96352 19.0726 4.91234 19.1034C4.44085 19.3874 2.22156 20.8892 1.67188 24.2951Z"
          fill="#3A97D4"
        />
        <path
          d="M5.74927 21.816C5.72829 21.816 5.70765 21.8067 5.69392 21.7891C5.34796 21.3434 5.15521 20.852 5.1472 20.8314C5.13347 20.7959 5.15137 20.7561 5.18728 20.7424C5.22331 20.7287 5.2635 20.7465 5.27734 20.782C5.27921 20.7869 5.4713 21.2759 5.80452 21.7054C5.82791 21.7355 5.8222 21.7787 5.79166 21.8019C5.77892 21.8114 5.76399 21.816 5.74927 21.816Z"
          fill="#3D4EAC"
        />
        <path
          d="M8.57715 23.0636L8.80153 23.0046L9.48268 22.8488C9.56472 22.83 9.65082 22.8443 9.7221 22.8887L10.3078 23.2528L8.69038 23.25L8.57715 23.0636Z"
          fill="url(#faqCommunityPaint26_linear)"
        />
        <path
          d="M6.39666 16.9617C6.39666 16.9617 6.81565 17.6499 6.88836 18.0303C6.96107 18.4106 7.07781 18.6484 6.6396 18.7839C6.2015 18.9194 5.79426 18.7479 5.5388 18.3928C5.5388 18.3928 5.13969 17.8464 5.17956 17.5962C5.21942 17.346 6.39666 16.9617 6.39666 16.9617Z"
          fill="url(#faqCommunityPaint27_linear)"
        />
        <path
          d="M5.65505 18.0984L5.71172 18.091C5.71172 18.091 5.41508 17.6481 5.60914 17.5383C5.80332 17.4284 6.59441 17.1588 6.7318 16.851C6.86426 16.5542 6.66613 16.219 6.42725 16.149C6.41209 16.1445 6.39617 16.1534 6.39068 16.168L6.37662 16.2053C6.36707 16.2309 6.33071 16.2319 6.31973 16.2069C6.30919 16.1832 6.27481 16.1822 6.26405 16.2057C6.22176 16.298 6.0948 16.4554 5.71436 16.5773C5.17455 16.7501 4.83826 17.019 4.87967 17.398C4.8823 17.422 4.85814 17.4386 4.83585 17.4288C4.7917 17.4095 4.72481 17.4064 4.64716 17.4904C4.51207 17.6364 4.61784 17.9557 4.98686 18.1817C5.00443 18.1924 5.00751 18.2168 4.9918 18.23C4.93656 18.2767 4.85891 18.3809 5.10855 18.4737C5.3639 18.5686 5.50327 18.5365 5.55478 18.5161C5.56862 18.5106 5.57652 18.4965 5.57367 18.4819L5.5081 18.1499C5.5047 18.1327 5.51667 18.1162 5.53424 18.1139L5.65505 18.0984Z"
          fill="url(#faqCommunityPaint28_linear)"
        />
        <path
          d="M5.67866 18.1668C5.67866 18.1668 5.49382 17.7164 5.29965 17.8747C5.10547 18.0331 5.4791 18.3829 5.65153 18.3782L5.67866 18.1668Z"
          fill="url(#faqCommunityPaint29_linear)"
        />
        <path
          d="M7.95164 23.5662C7.95427 23.5663 7.95691 23.5664 7.95954 23.5664L11.415 23.5631C11.5005 23.5629 11.5697 23.4946 11.5696 23.4104C11.5695 23.3261 11.4999 23.2565 11.4147 23.258L7.95921 23.2614C7.87377 23.2615 7.80469 23.3299 7.80469 23.4141C7.8048 23.4957 7.86992 23.5622 7.95164 23.5662Z"
          fill="url(#faqCommunityPaint30_linear)"
        />
        <path
          d="M12.7531 20.2227C12.7519 20.2249 12.7507 20.2273 12.7496 20.2297L11.2957 23.3269C11.2597 23.4034 11.2932 23.4942 11.3707 23.5297C11.4481 23.5652 11.5412 23.5322 11.5758 23.4553L13.0297 20.3581C13.0656 20.2815 13.0321 20.1907 12.9547 20.1553C12.8797 20.121 12.7912 20.1511 12.7531 20.2227Z"
          fill="url(#faqCommunityPaint31_linear)"
        />
        <path d="M21.8022 26.6788H21.4375V30.5756H21.8022V26.6788Z" fill="#7D97F4" />
        <path
          d="M23.2167 30.6509H20.0225C20.0225 30.5213 20.1288 30.4163 20.2599 30.4163H22.9794C23.1104 30.4164 23.2167 30.5213 23.2167 30.6509Z"
          fill="#7D97F4"
        />
        <path
          d="M23.691 26.5436C23.691 26.7567 23.5145 26.9311 23.2988 26.9311H20.067C19.8513 26.9311 19.6748 26.7567 19.6748 26.5436V26.1932C19.6748 25.98 19.8513 25.8057 20.067 25.8057H23.2988C23.5145 25.8057 23.691 25.98 23.691 26.1932V26.5436Z"
          fill="#7D97F4"
        />
        <path
          d="M18.9273 24.0805C18.9273 24.0805 18.3854 24.1635 17.7761 24.566C17.6802 24.6294 17.6276 24.7397 17.642 24.8528C17.7149 25.4238 18.015 27.272 19.0042 29.0772C19.0564 29.1723 19.0713 29.2831 19.0483 29.3889L18.8765 30.1791L18.5577 30.4744H19.3787L19.9253 29.0129L19.6916 28.9399C19.5104 28.8832 19.3861 28.7186 19.3828 28.5308L19.3553 26.9631C19.3787 26.2646 18.9218 25.7827 18.9218 25.7827L20.2328 25.7196L18.9273 24.0805Z"
          fill="url(#faqCommunityPaint32_linear)"
        />
        <path
          d="M18.2369 30.617C18.1621 30.7136 19.0416 30.8412 19.4859 30.6298C19.4859 30.6298 20.1018 29.571 20.1018 29.0259C20.1018 29.0259 20.0529 28.9148 19.6913 28.94C19.6913 28.94 19.4844 30.1936 19.2661 30.3341C19.0478 30.4745 18.6551 30.3483 18.6551 30.3483C18.6551 30.3483 18.3473 30.4743 18.2369 30.617Z"
          fill="url(#faqCommunityPaint33_linear)"
        />
        <path
          d="M20.0669 25.8056C20.0599 25.7937 18.6554 25.6388 18.6554 25.6388C18.6554 25.6388 18.7014 26.0607 18.5576 26.6415C18.414 27.2224 16.3518 29.739 16.3518 29.739L16.568 30.4162C15.8995 30.6462 15.2418 30.683 14.5947 30.5335L15.9347 29.739C15.9347 29.739 17.1889 25.5162 17.957 24.7629L19.3649 24.2817L20.0669 25.8056Z"
          fill="url(#faqCommunityPaint34_linear)"
        />
        <path
          d="M23.2162 23.3486C23.2162 23.3486 24.0254 24.5378 24.0918 25.0802C24.1583 25.6227 23.8702 25.8431 23.0211 25.8795C22.172 25.9158 19.9053 25.9153 19.9053 25.9153L17.9707 24.2817C17.9707 24.2817 19.3806 23.7846 19.7472 23.6441C20.1137 23.5035 23.2162 23.3486 23.2162 23.3486Z"
          fill="url(#faqCommunityPaint35_linear)"
        />
        <path
          d="M16.4861 30.1882C16.4861 30.1882 16.7189 30.411 16.5616 30.6588C16.5616 30.6588 14.7175 30.9818 14.2168 30.8353C14.2168 30.8353 14.3208 30.6008 14.8846 30.334L14.8429 30.3655C14.7903 30.4055 14.8212 30.4886 14.8874 30.4849C15.259 30.464 16.0279 30.3967 16.4861 30.1882Z"
          fill="url(#faqCommunityPaint36_linear)"
        />
        <path
          d="M19.711 15.8067C21.3652 15.5288 22.144 16.8619 22.087 17.142C22.0794 17.1791 22.1149 17.2106 22.1519 17.2007C22.3418 17.1503 22.7613 17.0866 23.0214 17.4085C23.2511 17.6928 23.0794 17.9611 22.9395 18.1083C22.9057 18.1439 22.9341 18.2017 22.9831 18.1956C23.3322 18.1521 24.0909 18.1416 24.215 18.8438C24.3308 19.4987 23.8695 19.6833 23.5959 19.7354C23.5496 19.7442 23.5388 19.8043 23.5786 19.8293C23.7151 19.9148 23.8732 20.0916 23.8093 20.4405C23.7092 20.9873 22.6241 20.9426 22.4973 20.9355C22.4893 20.935 22.4817 20.9327 22.4747 20.9287L20.7367 19.9494C20.7232 19.9418 20.7137 19.9285 20.7111 19.9134L20.3602 17.9112C20.3573 17.895 20.3625 17.8784 20.374 17.8666L19.2409 16.2867C18.8297 16.2268 19.6914 15.81 19.711 15.8067Z"
          fill="url(#faqCommunityPaint37_linear)"
        />
        <path
          d="M21.0276 23.1913C21.0276 23.1913 20.0853 22.3047 19.6214 21.7174C19.3707 21.4 19.2919 20.9821 19.4079 20.5961C19.6196 19.8912 19.996 19.161 20.0304 19.0474C20.083 18.8733 21.1397 18.8805 21.1397 18.8805C21.1397 18.8805 23.2189 20.2138 24.0358 22.4724L23.1183 22.7546C23.1183 22.7546 22.5754 21.8547 22.039 21.2826C22.039 21.2826 22.9672 22.4941 22.9999 22.9901L21.0276 23.1913Z"
          fill="#3A97D4"
        />
        <path
          d="M19.9033 18.074C19.9143 18.1102 20.1901 19.0135 20.2117 19.0323C20.2332 19.051 20.8165 19.082 21.091 18.8966L20.4101 17.5806L19.9033 18.074Z"
          fill="url(#faqCommunityPaint38_linear)"
        />
        <path
          d="M18.7969 16.9131C18.7969 16.9131 18.6636 17.4306 19.0324 18.1266C19.4011 18.8226 19.8185 18.8219 20.0467 18.5991C20.275 18.3763 20.5563 17.9982 20.4895 17.3254C20.4226 16.6525 18.9893 16.2549 18.7969 16.9131Z"
          fill="url(#faqCommunityPaint39_linear)"
        />
        <path
          d="M19.9798 17.3619C19.9798 17.3619 19.368 16.4378 18.7943 17.1651L18.7839 17.4296C18.7839 17.4296 18.112 17.3509 18.2296 16.7421C18.3473 16.1333 18.9279 15.7529 19.6757 16.0166C20.4236 16.2803 20.8521 16.5507 20.9003 17.0802C20.9485 17.6098 20.5222 17.4937 20.5222 17.4937C20.5222 17.4937 20.3832 17.1208 20.1854 17.0821C19.9877 17.0433 20.0391 17.334 20.0391 17.334L19.9798 17.3619Z"
          fill="url(#faqCommunityPaint40_linear)"
        />
        <path
          d="M20.1368 17.3496C20.1368 17.3496 19.8195 16.8764 20.1446 16.8058C20.4697 16.7353 20.4193 17.2669 20.3841 17.4635L20.1368 17.3496Z"
          fill="url(#faqCommunityPaint41_linear)"
        />
        <path
          d="M19.1084 23.257L19.8007 22.5977L20.7586 22.9974C20.7586 22.9974 21.712 23.1434 22.2576 22.9974C22.8032 22.8515 22.8032 22.8515 22.8032 22.8515L24.0362 22.4722L24.2299 23.013C24.2791 23.1502 24.2296 23.3027 24.1095 23.387C24.0028 23.4619 23.8777 23.5442 23.798 23.5786C23.6356 23.6489 19.7951 23.5301 19.7951 23.5301L19.1084 23.257Z"
          fill="url(#faqCommunityPaint42_linear)"
        />
        <path
          d="M21.7285 23.5679C21.7254 23.568 21.7224 23.5681 21.7193 23.5681L17.703 23.5637C17.6037 23.5636 17.5233 23.4841 17.5234 23.386C17.5235 23.2879 17.6044 23.2068 17.7034 23.2086L21.7197 23.2129C21.819 23.213 21.8994 23.2926 21.8993 23.3907C21.8991 23.4856 21.8234 23.5632 21.7285 23.5679Z"
          fill="url(#faqCommunityPaint43_linear)"
        />
        <path
          d="M14.0926 19.965H17.4374C17.6842 19.965 17.9088 20.1057 18.014 20.3262L19.5602 23.5658H15.4036C15.2446 23.5658 15.1017 23.4698 15.0432 23.3237L13.8442 20.3259C13.7749 20.1527 13.9041 19.965 14.0926 19.965Z"
          fill="url(#faqCommunityPaint44_linear)"
        />
        <path
          d="M16.449 21.626C16.5215 21.8484 16.4679 22.0646 16.3294 22.1086C16.1909 22.1527 16.0198 22.0081 15.9473 21.7856C15.8749 21.5631 15.9285 21.347 16.067 21.3029C16.2054 21.2588 16.3766 21.4034 16.449 21.626Z"
          fill="#7D97F4"
        />
        <path
          d="M26.3069 30.1596C26.3069 30.1596 26.3491 30.3909 26.3657 30.6654C26.3728 30.7824 26.2851 30.8841 26.167 30.8954C25.7559 30.9348 24.8202 31.0075 24.2227 30.9223C24.1391 30.9103 24.1058 30.8088 24.1677 30.7518C24.4141 30.525 25.0091 30.0839 25.9883 29.9874L26.3069 30.1596Z"
          fill="url(#faqCommunityPaint45_linear)"
        />
        <path
          d="M26.5114 29.6383L26.3036 30.1418C26.2752 30.2108 26.2043 30.2536 26.1293 30.2458C26.0785 30.2406 26.0182 30.231 25.9595 30.2134C25.8422 30.1782 25.7761 30.0554 25.8111 29.9393L25.968 29.4208L26.5114 29.6383Z"
          fill="url(#faqCommunityPaint46_linear)"
        />
        <path
          d="M30.0651 30.5605C30.0651 30.5605 29.6489 30.9046 29.4868 31.346C29.4422 31.4674 29.5364 31.5952 29.6671 31.5952H30.5236C30.6489 31.5952 30.745 31.4838 30.7232 31.3618C30.6688 31.0571 30.5114 30.5618 30.0651 30.5605Z"
          fill="url(#faqCommunityPaint47_linear)"
        />
        <path
          d="M30.5806 30.1128C30.5806 30.1128 30.4966 30.4804 30.4319 30.7234C30.4201 30.7674 30.3726 30.7916 30.3291 30.7763C30.1995 30.7305 30.0941 30.7104 30.0173 30.7205C29.9818 30.7251 29.9509 30.696 29.9504 30.6607L29.9424 30.05L30.5806 30.1128Z"
          fill="url(#faqCommunityPaint48_linear)"
        />
        <path
          d="M28.3665 21.8399L25.835 29.4706C25.835 29.4706 26.3205 29.7847 26.5518 29.7717L29.5587 23.6392C29.5587 23.6392 30.0848 27.4178 29.8298 30.1306L30.6911 30.1955C30.6911 30.1955 32.0915 23.2629 31.0308 20.8884L28.3665 21.8399Z"
          fill="url(#faqCommunityPaint49_linear)"
        />
        <path
          d="M29.5426 23.5733C29.5436 23.5733 29.5447 23.5732 29.5456 23.5731C29.559 23.5714 29.5686 23.5593 29.567 23.546L29.4688 22.7756C29.4671 22.7623 29.4549 22.7531 29.4414 22.7545C29.428 22.7562 29.4185 22.7683 29.4201 22.7816L29.5183 23.552C29.5198 23.5643 29.5304 23.5733 29.5426 23.5733Z"
          fill="url(#faqCommunityPaint50_linear)"
        />
        <path
          d="M27.8924 15.1252C27.8924 15.1252 29.9434 15.3051 29.6532 16.7491C29.6532 16.7491 30.787 18.8572 31.317 21.3405C31.3343 21.4214 31.2795 21.5002 31.1971 21.5141L27.7814 22.0919C27.6872 22.1078 27.5964 22.0498 27.5724 21.9583L26.7563 18.8419L26.4383 20.2908L25.4277 20.0307L26.2783 15.773C26.2785 15.773 26.3972 15.1519 27.8924 15.1252Z"
          fill="#067AB8"
        />
        <path
          d="M26.7389 18.7025C26.7407 18.7025 26.7425 18.7023 26.7443 18.702C26.758 18.699 26.7667 18.6856 26.7637 18.672L26.479 17.3803C26.4761 17.3667 26.4627 17.3581 26.4487 17.361C26.435 17.364 26.4263 17.3774 26.4293 17.391L26.714 18.6828C26.7167 18.6945 26.7272 18.7025 26.7389 18.7025Z"
          fill="#3A97D4"
        />
        <path
          d="M26.945 22.5462C27.0527 22.4878 27.1786 22.4716 27.2977 22.5009L27.5353 22.5594C27.5859 22.5719 27.6359 22.5375 27.6418 22.4863L27.8877 20.3235C28.1952 20.4679 28.6388 20.4506 28.7851 20.44L28.219 22.9896H26.2383L26.945 22.5462Z"
          fill="url(#faqCommunityPaint51_linear)"
        />
        <path
          d="M21.6826 17.855L21.7425 17.6584C21.777 17.5452 21.8615 17.4537 21.9726 17.4096L23.0554 16.9794C23.0554 16.9794 23.0717 16.8422 22.8266 16.7489C22.5814 16.6557 22.6388 16.3844 22.6388 16.3844C22.6388 16.3844 22.9736 16.5353 23.4639 16.7747C23.7443 16.9117 23.9016 17.1533 23.985 17.3353L26.4386 20.2907L26.1505 20.4862C25.7717 20.7434 25.2507 20.6171 25.0354 20.2158L23.7063 17.6975C23.0389 17.8958 22.3637 17.9337 21.6826 17.855Z"
          fill="url(#faqCommunityPaint52_linear)"
        />
        <path
          d="M27.2285 13.7466L27.8593 15.491C27.8593 15.491 27.3007 15.9225 26.893 15.7766L26.5752 14.7767C26.5751 14.7766 26.8179 13.5545 27.2285 13.7466Z"
          fill="url(#faqCommunityPaint53_linear)"
        />
        <path
          d="M25.3447 13.12C25.3447 13.147 25.9524 14.8197 25.9524 14.8197L26.9117 14.4566L26.5742 12.7866C26.5741 12.7866 25.3447 12.67 25.3447 13.12Z"
          fill="url(#faqCommunityPaint54_linear)"
        />
        <path
          d="M26.4064 13.1935L26.6537 14.0589C26.6569 14.0699 26.6586 14.0811 26.6586 14.0927C26.6587 14.1633 26.641 14.4062 26.3906 14.3731C26.1634 14.3432 25.9556 14.3191 25.8234 14.3806C25.7641 14.4082 25.7357 14.4757 25.7579 14.5367C25.8131 14.6886 25.944 15.0288 26.1034 15.3072C26.1357 15.3637 26.2066 15.3857 26.2665 15.3588C26.4467 15.2778 26.8218 15.0965 27.0891 14.8807C27.1253 14.8516 27.1427 14.8055 27.1341 14.7602C27.0921 14.5388 26.9669 13.8655 26.9614 13.6726C26.9562 13.4914 27.1619 13.3532 27.2524 13.3012C27.2821 13.2842 27.3033 13.2561 27.3113 13.2231C27.3397 13.1065 27.3638 12.8427 27.0232 12.7846C26.9519 12.7724 26.9055 12.705 26.92 12.6349C26.9487 12.4955 26.9395 12.2904 26.6716 12.2148C26.3993 12.1379 26.2279 12.3 26.1407 12.4241C26.1055 12.4743 26.0396 12.4906 25.9831 12.4657C25.951 12.4514 25.9122 12.4508 25.8704 12.4856C25.8142 12.5325 25.7285 12.5182 25.6873 12.4581C25.6078 12.3426 25.4635 12.2272 25.2193 12.3054C24.7859 12.444 24.8065 12.9377 24.9655 13.206C25.1244 13.4744 25.5856 13.2979 25.804 13.12C25.9701 12.9848 26.2228 13.0656 26.3345 13.1122C26.3696 13.1269 26.396 13.1569 26.4064 13.1935Z"
          fill="url(#faqCommunityPaint55_linear)"
        />
        <path
          d="M27.0482 13.9884C27.0482 13.9884 26.7916 13.509 26.9613 13.3585C27.1309 13.208 27.4183 13.2326 27.4106 13.6076C27.401 14.0737 27.0482 13.9884 27.0482 13.9884Z"
          fill="url(#faqCommunityPaint56_linear)"
        />
        <path
          d="M28.5859 20.4725C28.726 20.4725 28.8258 20.4619 28.8367 20.4607L28.8549 20.4587L29.6017 17.0182C29.6047 17.0047 29.5959 16.9913 29.5822 16.9884C29.5683 16.9854 29.5549 16.9941 29.552 17.0077L28.8128 20.4125C28.7017 20.4221 28.1082 20.4611 27.7948 20.2418L28.4418 17.2527L28.3067 17.0013C28.3002 16.989 28.2848 16.9844 28.2724 16.9909C28.26 16.9974 28.2552 17.0126 28.2618 17.0249L28.3882 17.2601L27.7383 20.2629L27.7512 20.2726C27.9777 20.4407 28.3423 20.4725 28.5859 20.4725Z"
          fill="#3A97D4"
        />
        <path
          d="M6.19654 30.9619C6.17941 30.9619 6.16195 30.9582 6.14514 30.9504C6.07914 30.9198 6.04861 30.8372 6.07705 30.766L8.80615 23.9242C8.83449 23.853 8.91104 23.8201 8.97715 23.8507C9.04316 23.8813 9.07369 23.9639 9.04525 24.0351L6.31615 30.8768C6.29495 30.9301 6.24695 30.9619 6.19654 30.9619Z"
          fill="url(#faqCommunityPaint57_linear)"
        />
        <path
          d="M9.27049 30.9619C9.22008 30.9619 9.17208 30.9301 9.15088 30.8769L6.42178 24.0352C6.39333 23.964 6.42386 23.8815 6.48987 23.8509C6.55588 23.8203 6.63243 23.8531 6.66087 23.9243L9.38998 30.7661C9.41842 30.8373 9.38789 30.9199 9.32188 30.9505C9.30508 30.9582 9.28762 30.9619 9.27049 30.9619Z"
          fill="url(#faqCommunityPaint58_linear)"
        />
        <path
          d="M24.718 30.9619C24.7009 30.9619 24.6834 30.9582 24.6666 30.9504C24.6006 30.9198 24.5701 30.8372 24.5985 30.766L27.3276 23.9242C27.3561 23.853 27.4325 23.8201 27.4987 23.8507C27.5647 23.8813 27.5952 23.9639 27.5667 24.0351L24.8376 30.8768C24.8164 30.9301 24.7684 30.9619 24.718 30.9619Z"
          fill="url(#faqCommunityPaint59_linear)"
        />
        <path
          d="M27.792 30.9619C27.7416 30.9619 27.6936 30.9301 27.6724 30.8769L24.9433 24.0352C24.9148 23.964 24.9453 23.8815 25.0114 23.8509C25.0774 23.8203 25.1539 23.8531 25.1824 23.9243L27.9115 30.7661C27.9399 30.8373 27.9094 30.9199 27.8434 30.9505C27.8266 30.9582 27.8092 30.9619 27.792 30.9619Z"
          fill="url(#faqCommunityPaint60_linear)"
        />
        <path
          d="M24.3232 23.4411H28.2759V23.0381H24.3232C24.4078 23.0381 24.4078 23.4411 24.3232 23.4411Z"
          fill="#91B3FA"
        />
        <path
          d="M28.2384 23.4411C28.2588 23.4411 28.2755 23.4246 28.2755 23.4045V23.0748C28.2755 23.0546 28.2588 23.0381 28.2384 23.0381H24.2394C24.219 23.0381 24.2023 23.0216 24.2023 23.0014V22.9836C24.2023 22.9635 24.219 22.947 24.2394 22.947H28.5018C28.596 22.947 28.6725 23.0225 28.6725 23.1156V23.3732C28.6725 23.461 28.6004 23.5322 28.5115 23.5322H24.2393C24.2188 23.5322 24.2021 23.5157 24.2021 23.4955V23.4777C24.2021 23.4575 24.2188 23.441 24.2393 23.441H28.2384V23.4411Z"
          fill="url(#faqCommunityPaint61_linear)"
        />
        <path
          d="M28.8479 23.7128C28.8611 23.6678 28.843 23.6214 28.7985 23.586C28.7541 23.5506 28.6878 23.5302 28.6179 23.5302H4.93367C4.84174 23.5302 4.75608 23.5609 4.70655 23.6117C4.65701 23.6625 4.65031 23.7263 4.68897 23.781L4.94564 24.145C5.0044 24.2284 5.1318 24.2818 5.27194 24.2818H28.4553C28.5857 24.2818 28.6977 24.2212 28.7224 24.1373L28.8479 23.7128Z"
          fill="url(#faqCommunityPaint62_linear)"
        />
        <ellipse cx="21.5" cy="7.37533" rx="4" ry="3.93349" fill="#DC0D16" />
        <path
          d="M21.2777 4.97156H19.9443L22.3888 7.37536L19.9443 9.77916H21.2777L23.7221 7.37536L21.2777 4.97156Z"
          fill="white"
        />
        {state === 'default' && (
          <g style={{mixBlendMode: 'saturation'}}>
            <rect y="-0.299377" width="32" height="32" fill="white" />
          </g>
        )}
      </g>
      <defs>
        <linearGradient
          id="faqCommunityPaint0_linear"
          x1="35.3012"
          y1="36.7186"
          x2="7.44065"
          y2="7.65041"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DAE3FE" />
          <stop offset="1" stopColor="#E9EFFD" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint1_linear"
          x1="13.7868"
          y1="29.0855"
          x2="7.17736"
          y2="36.2245"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint2_linear"
          x1="11.6306"
          y1="29.5942"
          x2="12.2192"
          y2="29.5999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint3_linear"
          x1="14.3865"
          y1="21.036"
          x2="8.76232"
          y2="28.5997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint4_linear"
          x1="12.3132"
          y1="27.721"
          x2="5.70361"
          y2="34.8603"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint5_linear"
          x1="9.50925"
          y1="29.5444"
          x2="10.0175"
          y2="29.5493"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint6_linear"
          x1="13.388"
          y1="20.2938"
          x2="7.76384"
          y2="27.8574"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint7_linear"
          x1="-11.2852"
          y1="-21.3492"
          x2="-6.18282"
          y2="-12.3981"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FE7062" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint8_linear"
          x1="14.199"
          y1="14.4439"
          x2="17.2064"
          y2="14.4728"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint9_linear"
          x1="16.5417"
          y1="13.9678"
          x2="14.521"
          y2="18.5035"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint10_linear"
          x1="17.2122"
          y1="12.4632"
          x2="18.0523"
          y2="11.1827"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint11_linear"
          x1="-13.0537"
          y1="-20.3408"
          x2="-7.95135"
          y2="-11.3897"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FE7062" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint12_linear"
          x1="7.57822"
          y1="18.5906"
          x2="11.3839"
          y2="18.6272"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint13_linear"
          x1="10.5393"
          y1="14.5982"
          x2="12.0439"
          y2="14.6127"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint14_linear"
          x1="12.4111"
          y1="13.67"
          x2="10.7775"
          y2="12.6399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint15_linear"
          x1="9.9003"
          y1="11.5983"
          x2="12.0014"
          y2="13.3109"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint16_linear"
          x1="11.973"
          y1="14.0377"
          x2="10.5031"
          y2="13.1108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint17_linear"
          x1="12.4042"
          y1="16.2014"
          x2="14.4569"
          y2="24.8578"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint18_linear"
          x1="10.9649"
          y1="18.4885"
          x2="12.1546"
          y2="18.4885"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint19_linear"
          x1="7.39243"
          y1="24.2125"
          x2="7.63395"
          y2="30.2016"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F52FF" />
          <stop offset="1" stopColor="#4042E2" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint20_linear"
          x1="6.61036"
          y1="29.847"
          x2="7.23795"
          y2="29.847"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint21_linear"
          x1="10.7058"
          y1="24.0787"
          x2="10.9474"
          y2="30.068"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F52FF" />
          <stop offset="1" stopColor="#4042E2" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint22_linear"
          x1="3.1405"
          y1="27.049"
          x2="8.10856"
          y2="27.049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint23_linear"
          x1="9.58297"
          y1="29.8992"
          x2="10.3322"
          y2="29.8992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint24_linear"
          x1="7.65299"
          y1="25.0039"
          x2="2.27258"
          y2="31.1781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint25_linear"
          x1="5.42142"
          y1="19.4673"
          x2="5.7832"
          y2="18.5439"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint26_linear"
          x1="8.57715"
          y1="23.0466"
          x2="10.3078"
          y2="23.0466"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint27_linear"
          x1="6.5498"
          y1="17.5955"
          x2="4.91626"
          y2="19.2446"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint28_linear"
          x1="5.11133"
          y1="16.6663"
          x2="7.03558"
          y2="18.5908"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint29_linear"
          x1="6.2824"
          y1="17.3308"
          x2="4.64886"
          y2="18.9798"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint30_linear"
          x1="-362.301"
          y1="-368.141"
          x2="-356.273"
          y2="-361.618"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint31_linear"
          x1="528.209"
          y1="-146.3"
          x2="519.734"
          y2="-143.489"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint32_linear"
          x1="14.9178"
          y1="42.1699"
          x2="19.4399"
          y2="23.8625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint33_linear"
          x1="25.3845"
          y1="19.7729"
          x2="21.0648"
          y2="27.1333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint34_linear"
          x1="17.1654"
          y1="30.6071"
          x2="18.0687"
          y2="16.9705"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint35_linear"
          x1="17.9707"
          y1="24.632"
          x2="24.101"
          y2="24.632"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint36_linear"
          x1="22.8726"
          y1="18.299"
          x2="18.5536"
          y2="25.6584"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint37_linear"
          x1="22.2889"
          y1="14.5153"
          x2="21.407"
          y2="19.2048"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint38_linear"
          x1="20.7957"
          y1="20.3776"
          x2="20.4349"
          y2="18.0344"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint39_linear"
          x1="18.7865"
          y1="17.895"
          x2="21.549"
          y2="16.9519"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint40_linear"
          x1="18.1962"
          y1="15.4527"
          x2="21.3866"
          y2="19.0326"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint41_linear"
          x1="18.7047"
          y1="17.6536"
          x2="21.4673"
          y2="16.7104"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint42_linear"
          x1="22.3123"
          y1="23.0366"
          x2="27.4526"
          y2="23.0366"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint43_linear"
          x1="22.4099"
          y1="22.5135"
          x2="19.2292"
          y2="23.5694"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint44_linear"
          x1="18.0302"
          y1="20.4481"
          x2="15.7633"
          y2="22.873"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint45_linear"
          x1="25.2166"
          y1="34.3473"
          x2="25.1835"
          y2="37.8982"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint46_linear"
          x1="26.083"
          y1="30.382"
          x2="26.2651"
          y2="28.941"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint47_linear"
          x1="30.0725"
          y1="34.3926"
          x2="30.0394"
          y2="37.9435"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint48_linear"
          x1="30.1586"
          y1="30.8757"
          x2="30.3361"
          y2="29.4715"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint49_linear"
          x1="27.2985"
          y1="19.5434"
          x2="28.9424"
          y2="27.6425"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint50_linear"
          x1="30.2679"
          y1="24.6942"
          x2="30.0684"
          y2="24.2903"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F52FF" />
          <stop offset="1" stopColor="#4042E2" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint51_linear"
          x1="28.7851"
          y1="21.6566"
          x2="26.2384"
          y2="21.6566"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint52_linear"
          x1="26.4385"
          y1="18.5023"
          x2="21.6826"
          y2="18.5023"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint53_linear"
          x1="28.1511"
          y1="15.8668"
          x2="26.5163"
          y2="14.1693"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint54_linear"
          x1="25.7497"
          y1="13.1319"
          x2="27.7818"
          y2="15.8527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint55_linear"
          x1="28.8959"
          y1="5.46061"
          x2="26.7651"
          y2="11.7099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1473D" />
          <stop offset="1" stopColor="#E9605A" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint56_linear"
          x1="28.7726"
          y1="15.2679"
          x2="27.1378"
          y2="13.5703"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint57_linear"
          x1="2.21477"
          y1="46.2381"
          x2="8.20916"
          y2="24.6031"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F52FF" />
          <stop offset="1" stopColor="#4042E2" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint58_linear"
          x1="2.53435"
          y1="46.3267"
          x2="8.52872"
          y2="24.6918"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F52FF" />
          <stop offset="1" stopColor="#4042E2" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint59_linear"
          x1="19.386"
          y1="50.9958"
          x2="25.3803"
          y2="29.3608"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F52FF" />
          <stop offset="1" stopColor="#4042E2" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint60_linear"
          x1="19.7056"
          y1="51.0844"
          x2="25.6999"
          y2="29.4494"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F52FF" />
          <stop offset="1" stopColor="#4042E2" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint61_linear"
          x1="28.0344"
          y1="16.5588"
          x2="27.0717"
          y2="20.651"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
        <linearGradient
          id="faqCommunityPaint62_linear"
          x1="9.76597"
          y1="48.3301"
          x2="15.7603"
          y2="26.6952"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F52FF" />
          <stop offset="1" stopColor="#4042E2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CommunityIcon;
