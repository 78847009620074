import styled from 'styled-components';
import colors from 'styles/colors';
import {b1} from 'styles/fonts';

export const Table = styled.table`
  background: #fff;
  border-spacing: 0px;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  overflow: hidden;
  table-layout: fixed;
  width: 480px;
`;

export const THead = styled.thead`
  background: ${colors.white};
  color: ${colors.palette.gray['400']};

  th {
    // border-top: 1px solid #eee;
    padding: 15px;
    border-right: 1px solid #eee !important;
    font-weight: 900;
    width: 100%;
    font-weight: 1000;
    font-size: 16px;
    &:first-child {
      border-right: 1px solid #eee;
      // width: 0;
      // padding: 10px 20px;
      text-align: center;
    }
    &:last-child {
      border-right: none !important;
    }
  }
`;

export const Th = styled.th`
  ${b1.regular}
  padding: 8px 16px;
  text-align: center;

  &:not(:last-child) {
    border-right: 1px solid ${colors.white};
  }
`;

export const Tr = styled.tr`
  // &:not(:last-child) {
  //   border-bottom: 1px solid ${colors.palette.gray['075']};
  // }
  // background: rgba(0, 0, 0, 0.05);
  text-align: center;
  cursor: pointer;
  &:hover {
    td {
      background: rgba(0, 0, 0, 0.05);
      cursor: pointer;
    }
  }
`;

export const Td = styled.td`
  border-top: 1px solid #eee;
  padding: 15px;
  border-right: 1px solid #eee;
  font-weight: 900;
  width: 100%;
  &:first-child {
    border-right: 1px solid #eee;
    width: 0;
    padding: 10px 20px;
    text-align: center;
  }
  &:last-child {
    border-right: none;
  }
`;
