import React, {FC} from 'react';

import {useWindowDimensions} from 'hooks';

import HeroV2 from './qrcode.jpeg';
// import HeroV2Placeholder from './qrcode.jpeg';

import * as S from './Styles';

const AboutUsHero: FC = () => {
  const {width} = useWindowDimensions();

  return (
    <S.Container>
      <S.LeftContainer>
        <S.AboutUsText>联系象寄</S.AboutUsText>
        <S.Title>微信服务号：象寄科技服务</S.Title>
        <S.Subtitle>
          扫描公众号二维码或在微信中搜索“象寄科技服务”与我们取得联系，我们的客服人员将悉心为您解答疑问，解决问题。
        </S.Subtitle>
        <S.Subtitle>周一至周六，上午: 9:00 – 下午 18:00(周日及节假日除外)</S.Subtitle>
      </S.LeftContainer>
      {width > 414 && (
        <S.HeroImage
          alt="About Us Hero Image"
          containerClassName="AboutUs__HeroImage"
          placeholderSrc={HeroV2}
          realSrc={HeroV2}
        />
      )}
    </S.Container>
  );
};

export default AboutUsHero;
