import {EMAIL} from './contact';

const utmSource = new URL(window.location.href).searchParams.get('utm_source');

const query = utmSource ? `?utm_source=${utmSource}` : '';
// console.log(1111111111, utmSource, query);

// Internal URLs
export const ROUTES = {
  aboutUs: '/about-us-cn',
  aboutUsEn: '/about-us',
  analytics: '/analytics',
  apps: '/apps',
  assets: '/assets',
  bounties: '/bounties',
  contact: '/contact-cn',
  contactUsEn: '/contact',
  createAccount: '/create-account',
  deliveryPolicy: '/delivery-policy',
  deploymentGuide: '/deployment-guide',
  donate: '/donate',
  download: '/download',
  erp: '/erp',
  faq: '/faq',
  guidelines: '/guidelines',
  language: '/language',
  openings: '/openings',
  price: '/pricing',
  privacyPolicy: '/privacy-policy',
  returnPolicy: '/return-policy',
  roadmap: '/roadmap',
  signin: 'sign-in',
  signout: '/sign-out',
  social: '/social',
  styleGuide: '/style-guide',
  teams: '/teams',
  termsOfService: '/terms-of-service',
  termsOfUse: '/terms-of-use',
  useApi: '/useApi',
  users: '/users',
  useWeb: '/useWeb',
  wallet: '/wallet',
  walletProgress: '/wallet-progress',
};

// External URLs
const developerPortalUrl = 'https://developer.thenewboston.com';

export const URLS = {
  apiDoc: `https://xiangjifanyi.com/doc/apiDoc${query}`,
  apps: {
    faucet: 'https://tnbfaucet.com/',
  },
  blog: 'https://blog.thenewboston.com',
  contact: `mailto:${EMAIL}`,

  developerPortal: {
    api: `${developerPortalUrl}/api`,
    home: developerPortalUrl,
    nodeDeployment: `${developerPortalUrl}/node-deployment`,
    projects: `${developerPortalUrl}/projects`,
    sdkAndLibraries: `${developerPortalUrl}/sdks-and-libraries`,
    tutorials: `${developerPortalUrl}/tutorials`,
    utilities: `${developerPortalUrl}/utilities`,
    whitepaper: `${developerPortalUrl}/whitepaper`,
  },
  discord: 'https://discord.com/invite/thenewboston',
  github: 'https://github.com/thenewboston-developers',
  guide: `https://xiangjifanyi.com/doc/guide${query}`,
  imgKoutu: `https://xiangjifanyi.com/console/workspace/koutu${query}`,
  imgLink: `https://xiangjifanyi.com/console/workspace/image-link${query}`,
  imgTrans: `https://xiangjifanyi.com/console/workspace${query}`,
  imgWaterMark: `https://xiangjifanyi.com/console/user/setting${query}`,
  jingxiuDoc: `https://xiangjifanyi.com/doc/jingxiuDoc${query}`,
  login: `https://xiangjifanyi.com/console/workspace${query}`,
  proxyLogin: `https://xiangjifanyi.com/doc/proxyMechanism${query}`,
  proxyMechanism: `https://xiangjifanyi.com/doc/proxyMechanism${query}`,
  tnbFaucet: 'https://tnbfaucet.com',
  videoTrans: `https://xiangjifanyi.com/video${query}`,
};
