import GoogleLogo from 'assets/logos/google.jpeg';
import PapagoLogo from 'assets/logos/papago.jpg';
import AliLogo from 'assets/logos/alibaba.png';
import DeeplLogo from 'assets/logos/deepl.png';

export const targetLan = [
  {
    ali: true,
    deepl: false,
    example: 'الطقس جميل اليوم',
    google: true,
    name: '阿拉伯语(AR)',
    papago: false,
  },
  {
    ali: true,
    deepl: false,
    example: '今天天氣不錯',
    google: true,
    name: '中文繁体(CHT)',
    papago: true,
  },
  {
    ali: true,
    deepl: true,
    example: 'dnes je hezké počasí',
    google: true,
    name: '捷克语(CSY)',
    papago: false,
  },
  {
    ali: true,
    deepl: true,
    example: 'schönes Wetter heute',
    google: true,
    name: '德语(DEU)',
    papago: false,
  },
  {
    ali: true,
    deepl: true,
    example: 'the weather is nice today',
    google: true,
    name: '英语(ENG)',
    papago: true,
  },
  {
    ali: true,
    deepl: true,
    example: 'el clima es agradable hoy',
    google: true,
    name: '西班牙语(ESP)',
    papago: false,
  },
  {
    ali: true,
    deepl: true,
    example: "le temps est beau aujourd'hui",
    google: true,
    name: '法语(FRA)',
    papago: false,
  },
  {
    ali: true,
    deepl: true,
    example: 'ma szép az idő',
    google: true,
    name: '匈牙利语(HUN)',
    papago: false,
  },
  {
    ali: true,
    deepl: false,
    example: 'Cuacanya bagus hari ini',
    google: true,
    name: '印尼语(ID)',
    papago: false,
  },
  {
    ali: true,
    deepl: true,
    example: 'il tempo è bello oggi',
    google: true,
    name: '意大利语(ITA)',
    papago: false,
  },
  {
    ali: true,
    deepl: true,
    example: '今日は良い天気',
    google: true,
    name: '日语(JPN)',
    papago: true,
  },
  {
    ali: true,
    deepl: false,
    example: 'cuaca apik dina iki',
    google: true,
    name: '爪哇语(JW)',
    papago: false,
  },
  {
    ali: true,
    deepl: false,
    example: '오늘 날씨가 좋네요',
    google: true,
    name: '韩语(KOR)',
    papago: true,
  },
  {
    ali: true,
    deepl: false,
    example: 'cuaca hari ini sangat baik',
    google: true,
    name: '马来语(MS)',
    papago: false,
  },
  {
    ali: true,
    deepl: false,
    example: 'ဒီနေ့ရာသီဥတုကောင်းတယ်',
    google: true,
    name: '缅甸语(MY)',
    papago: false,
  },
  {
    ali: true,
    deepl: true,
    example: 'het is mooi weer vandaag',
    google: true,
    name: '荷兰语(NLD)',
    papago: false,
  },
  {
    ali: true,
    deepl: true,
    example: 'Dziś jest ładna pogoda',
    google: true,
    name: '波兰语(PLK)',
    papago: false,
  },
  {
    ali: true,
    deepl: true,
    example: 'o tempo está bom hoje',
    google: true,
    name: '葡萄牙语(PTB)',
    papago: false,
  },
  {
    ali: true,
    deepl: true,
    example: 'vremea este frumoasă azi',
    google: true,
    name: '罗马尼亚语(ROM)',
    papago: false,
  },
  {
    ali: true,
    deepl: true,
    example: 'хорошая погода сегодня',
    google: true,
    name: '俄语(RUS)',
    papago: false,
  },
  {
    ali: true,
    deepl: false,
    example: 'วันนี้อากาศดี',
    google: true,
    name: '泰语(TH)',
    papago: false,
  },
  {
    ali: true,
    deepl: false,
    example: 'maganda ang panahon ngayon',
    google: true,
    name: '他加禄语(菲律宾)(TL)',
    papago: false,
  },
  {
    ali: true,
    deepl: false,
    example: 'Bugün hava güzel',
    google: true,
    name: '土耳其语(TRK)',
    papago: false,
  },
  {
    ali: true,
    deepl: false,
    example: 'thời tiết hôm nay đẹp',
    google: true,
    name: '越南语(VIN)',
    papago: false,
  },
];
export const engines = [
  {
    key: 'google',
    name: 'Google',
    thumbnail: GoogleLogo,
    url: 'https://translate.google.cn/',
  },
  {
    key: 'papago',
    name: 'Papago',
    thumbnail: PapagoLogo,
    url: 'https://papago.naver.com/',
  },
  {
    key: 'ali',
    name: 'Ali',
    thumbnail: AliLogo,
    url: 'https://www.alifanyi.com/',
  },
  {
    key: 'deepl',
    name: 'DeepL',
    thumbnail: DeeplLogo,
    url: 'https://www.deepl.com/translator',
  },
];
