import styled from 'styled-components';
import zIndex from 'styles/zIndex';
import colors from 'styles/colors';

import {Container} from 'components';
import TopNav from 'containers/TopNav';

const topNavHeight = '60px';
const footerHeight = '348px';

export const Layout = styled.div`
  position: relative;
`;

export const LayoutContent = styled.div`
  min-height: calc(100vh - ${topNavHeight + footerHeight});
`;

export const LayoutFooter = styled(Container)`
  padding: 80px 48px 0px 48px;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const LayoutTopNavWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: ${zIndex.layout};
`;

export const LayoutTopNav = styled(TopNav)``;

export const LayoutFooterWrapper = styled.div`
  background: ${colors.palette.neutral['075']};
  display: flex;
  justify-content: center;
  // padding: 80px 48px;

  @media (max-width: 768px) {
    justify-content: start;
  }
`;
export const CopyrightContainer = styled.div`
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
  padding: 30px 24px;
  overflow: hidden;
  background: #f1f6fa;
  width: 100vw;
  border-top: 1px solid azure;
  max-width: 100% !important;
`;
export const CopyrightLi = styled.li`
  list-style-type: none;
  text-align: center;
  line-height: 20px;
  font-size: 11px;
`;
