import React from 'react';
import {useHistory} from 'react-router';

import {EmojiIcon, EmojiType} from 'components';
import {URLS} from 'constants/routes';
import {useWindowDimensions} from 'hooks';
import breakpoints from 'styles/breakpoints';

import * as S from './Styles';

const Links = () => {
  const history = useHistory();
  const {width} = useWindowDimensions();

  const items = [
    {
      description: '去除视频硬字幕，替换为翻译语种字幕。支持多种热门背景音乐以及人声旁白合成',
      emoji: EmojiType.Video,
      emojiBgColor: '#FFB802',
      isExternal: true,
      title: '视频翻译',
      url: URLS.videoTrans,
    },
    {
      description: '支持网页版图片批量翻译和API调用，图片、文字水印轻松配置',
      emoji: EmojiType.Image,
      emojiBgColor: '#4F52FF',
      isExternal: true,
      title: '图片翻译',
      url: URLS.imgTrans,
    },
    {
      description: '简单易用的交互精修工具，拖拖拽拽即可做出精美素材（图片+视频）',
      emoji: EmojiType.Jingxiu,
      emojiBgColor: '#0085FF',
      isExternal: true,
      title: '交互精修',
      url: URLS.imgTrans,
    },
    {
      description: '支持自动和交互式抠白底图功能，轻松制作主图',
      emoji: EmojiType.Koutu,
      emojiBgColor: '#2DA15D',
      isExternal: true,
      title: '一键抠图',
      url: URLS.imgKoutu,
    },
  ];

  const getEmojiContainerSize = () => {
    if (width > parseInt(breakpoints.large, 10)) {
      return 156;
    }
    return 120;
  };

  const getEmojiSize = () => {
    if (width > parseInt(breakpoints.large, 10)) {
      return 64;
    }
    return 48;
  };

  const navigate = (isExternal: boolean, url: string) => {
    if (isExternal) {
      const target = url.includes('mailto:') ? '_self' : '_blank';
      window.open(url, target, 'noreferrer noopener');
    } else {
      history.push(url);
    }
  };

  return (
    <div>
      <svg
        data-v-7f8c5f22=""
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        style={{position: 'absolute', zIndex: -999}}
      >
        <path
          data-v-7f8c5f22=""
          fill="#f2f8ff"
          fillOpacity="1"
          d="M0,64L40,64C80,64,160,64,240,85.3C320,107,400,149,480,144C560,139,640,85,720,74.7C800,64,880,96,960,122.7C1040,149,1120,171,1200,170.7C1280,171,1360,149,1400,138.7L1440,128L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"
        />
      </svg>

      <S.Container>
        {items.map((item) => (
          <S.Item key={item.title} role="button" tabIndex={0} onClick={() => navigate(item.isExternal, item.url)}>
            <EmojiIcon
              emoji={item.emoji}
              size={getEmojiContainerSize()}
              emojiSize={getEmojiSize()}
              color={item.emojiBgColor}
              zoomOnHover
            />
            <S.ItemTitle>{item.title}</S.ItemTitle>
            <S.ItemDescription>{item.description}</S.ItemDescription>
          </S.Item>
        ))}
      </S.Container>
    </div>
  );
};

export default Links;
