import React, {FC, memo, useCallback} from 'react';

import {ROUTES, URLS} from 'constants/routes';
// import colors from 'styles/colors';
// import {SocialMedia} from 'types/social-media';
// import {socialMediaUrls} from 'utils/social-media';

import FooterNavList from './FooterNavList';
import * as S from './Styles';

interface ComponentProps {
  className?: string;
}

const navLists = [
  // {
  //   header: '象寄图片翻译',
  //   links: [
  //     {
  //       title: '关于我们',
  //       url: ROUTES.bounties,
  //     },
  //   ],
  // },
  {
    header: '产品服务',
    links: [
      {
        isExternal: true,
        newWindow: false,
        title: '图片翻译',
        url: URLS.imgTrans,
      },
      {
        isExternal: true,
        newWindow: false,
        title: '视频翻译',
        url: URLS.videoTrans,
      },
      {
        isExternal: false,
        newWindow: false,
        title: '支持语言',
        url: ROUTES.language,
      },
      {
        isExternal: true,
        newWindow: false,
        title: '使用指南',
        url: URLS.jingxiuDoc,
      },
      // {
      //   isExternal: true,
      //   newWindow: false,
      //   title: '成为代理',
      //   url: URLS.developerPortal.projects,
      // },
    ],
  },
  {
    header: 'API对接',
    links: [
      {
        isExternal: true,
        newWindow: false,
        title: 'API对接文档',
        url: URLS.apiDoc,
      },
      {
        isExternal: true,
        newWindow: false,
        title: '精修工具对接文档',
        url: URLS.jingxiuDoc,
      },
      // {
      //   title: 'ERP合作伙伴',
      //   url: ROUTES.roadmap,
      // },
    ],
  },
  {
    header: '代理与返现',
    links: [
      {
        isExternal: true,
        newWindow: false,
        title: '代理合作机制',
        url: URLS.proxyMechanism,
      },
    ],
  },
  {
    header: '联系',
    links: [
      // {
      //   isExternal: true,
      //   newWindow: true,
      //   title: (
      //     <S.SocialMediaLink>
      //       {/* material ui's discord icon is slightly different from the design, hence we will use the custom one */}
      //       <S.DiscordIcon color={colors.palette.neutral['400']} />
      //       {SocialMedia.discord}
      //     </S.SocialMediaLink>
      //   ),
      //   url: socialMediaUrls[SocialMedia.discord],
      // },
      {
        title: '微信客服',
        url: ROUTES.contact,
      },
      {
        title: '联系我们',
        url: ROUTES.contactUsEn,
      },
      {
        title: '关于我们',
        url: ROUTES.aboutUsEn,
      },
    ],
  },
  {
    header: '声明',
    links: [
      {
        title: '使用条款',
        url: ROUTES.termsOfUse,
      },
      // {
      //   title: 'Terms of service',
      //   url: ROUTES.termsOfService,
      // },
      {
        title: '隐私条款',
        url: ROUTES.privacyPolicy,
      },
      {
        title: '退款说明',
        url: ROUTES.returnPolicy,
      },
      {
        title: '交付说明',
        url: ROUTES.deliveryPolicy,
      },
    ],
  },
];

const Footer: FC<ComponentProps> = ({className}) => {
  const renderNavLists = useCallback(
    () => navLists.map((list) => <FooterNavList header={list.header} key={list.header} links={list.links} />),
    [],
  );

  return (
    <S.Container className={className} data-testid="Footer">
      {renderNavLists()}
    </S.Container>
  );
};

export default memo(Footer);
