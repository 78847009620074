import styled from 'styled-components';

export const Container = styled.footer`
  margin: 10px 30px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  // background: #272c32;

  @media (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const LinkItemHref = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
`;

export const LinkItem = styled.img`
  object-fit: contain;
  transition: transform 0.15s;
`;
