import React from 'react';
// import {useHistory} from 'react-router';

// import {ROUTES, URLS} from 'constants/routes';

// import WalletImage from 'assets/gifs/imageEditor.gif';

import * as S from './Styles';

const ImageEditor = () => {
  // const history = useHistory();

  return (
    <S.Wrapper>
      <S.Container>
        <S.ImageContainer>
          <S.Video
            className="lazy"
            muted
            // preload
            poster="https://i.tosoiot.com/static/images/imageEditorPoster.jpg"
            data-src="https://i.tosoiot.com/static/images/imageEditor.mp4"
            autoPlay
            loop
          />
        </S.ImageContainer>
        <S.Content>
          <S.Heading>简单易用的图片精修工具，不会PS也能轻松修出精美图片</S.Heading>
          <S.Paragraph>图文分离，文本框矢量操作，支持文本框批量修改，拖拖拽拽即可轻松快速修图</S.Paragraph>
          {/* <S.A href={URLS.tnbFaucet}>免费</S.A> */}
          {/* <S.Button variant="outlined" onClick={() => history.push(ROUTES.download)}>
            Download Wallet
          </S.Button> */}
        </S.Content>
      </S.Container>
    </S.Wrapper>
  );
};

export default ImageEditor;
