import React, {FC} from 'react';

import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import {Button} from 'components';
import {ROUTES, URLS} from 'constants/routes';
import {useWindowDimensions} from 'hooks';

import GlobeImage from './Globe.png';
import * as S from './Styles';

const TRANSACTIONS_PER_SECOND = 200;
const LATENCY = 0.872;
const COUNT_UP_DURATION = 1.5;
const COUNT_UP_DEFAULT_DURATION = 100000;
const COUNT_UP_DELAY = 0.3;

const HomeSpeed: FC = () => {
  const {width} = useWindowDimensions();
  const [viewPortEntered, setViewPortEntered] = React.useState(false);

  return (
    <S.Container>
      <VisibilitySensor
        active={!viewPortEntered}
        onChange={(isVisible) => {
          if (isVisible) {
            setViewPortEntered(true);
          }
        }}
        delayedCall
      >
        <>
          <S.Title>
            支持DeepL、谷歌、Papago、阿里云等文本引擎，
            {/* <br /> */}
            基于深度学习的图片翻译模型，翻译精准高效
          </S.Title>
          <S.SubTitle>
            覆盖横向文字，纵斜向文字，复杂背景等图片场景，文本引擎可自由根据语种选择{' '}
            {/* <S.Link href={URLS.developerPortal.tutorials} newWindow={false} showNewWindowIcon={false}>
              查看支持的语种
            </S.Link> */}
          </S.SubTitle>
          <Button
            variant="outlined"
            // onClick={() => {
            //   window.location.href = ROUTES.language;
            // }}
          >
            <S.RouterLink to={ROUTES.language}>查看支持的语种</S.RouterLink>
          </Button>
          <S.StatisticsContainer>
            <S.StatisticSection>
              <S.StatisticTitle>语言（种）</S.StatisticTitle>
              <S.StatisticNumber>
                <CountUp
                  delay={COUNT_UP_DELAY}
                  duration={viewPortEntered ? COUNT_UP_DURATION : COUNT_UP_DEFAULT_DURATION}
                  end={TRANSACTIONS_PER_SECOND}
                  separator=","
                  start={0}
                  useEasing
                />
              </S.StatisticNumber>
              <S.StatisticDescription>可支持语种数量</S.StatisticDescription>
            </S.StatisticSection>
            <S.StatisticDivider type={width > 992 ? 'vertical' : 'horizontal'} />
            <S.StatisticSection>
              <S.StatisticTitle>速度（秒）</S.StatisticTitle>
              <S.StatisticNumber>
                <CountUp
                  decimals={3}
                  delay={COUNT_UP_DELAY}
                  duration={viewPortEntered ? COUNT_UP_DURATION : COUNT_UP_DEFAULT_DURATION}
                  end={LATENCY}
                  start={5}
                  useEasing
                />
              </S.StatisticNumber>
              <S.StatisticDescription>平均翻译一张图片</S.StatisticDescription>
            </S.StatisticSection>
            <S.StatisticDivider type={width > 992 ? 'vertical' : 'horizontal'} />
            <S.StatisticSection>
              <S.StatisticTitle>定价灵活</S.StatisticTitle>
              <S.StatisticTransactionDescription>
                支持包月模式（铺货）
                <br />
                张数套餐超高性价比
                <br />
                <S.Link href={URLS.proxyMechanism} newWindow showNewWindowIcon>
                  推荐朋友赢现金额度
                </S.Link>
              </S.StatisticTransactionDescription>
            </S.StatisticSection>
          </S.StatisticsContainer>
          <S.Image alt="xiangji Globe" src={GlobeImage} />
        </>
      </VisibilitySensor>
    </S.Container>
  );
};

export default HomeSpeed;
