import React, {FC} from 'react';
// import {useHistory} from 'react-router-dom';

// import {getApps} from 'apis/apps';
// import {Button, Loader} from 'components';
// import {URLS} from 'constants/routes';
// import {ApiProgress} from 'constants/api-progress';
// import {useWindowDimensions} from 'hooks';
// import DefaultLogoSrc from 'assets/images/logo.png';
import RegisterButton from './RegisterButton';
import CheckMark from './CheckMark';

import * as S from './Styles';

// type AppThumbnail = {
//   name: string;
//   thumbnail: string;
//   slug: string;
// };

const HEADING_HIGHLIGHT_DELAY = 2000;
// const APP_COUNT_MAX = 9;
// const APP_COUNT_1366 = 7;
// const APP_COUNT_768 = 5;

const HomeHero: FC = () => {
  // const history = useHistory();
  // const {width} = useWindowDimensions();
  const [highlightedHeading, setHighlightedHeading] = React.useState<0 | 1 | 2>(0);
  // const [progress, setProgress] = React.useState<ApiProgress>(ApiProgress.Init);
  // const [apps, setApps] = React.useState<AppThumbnail[]>([]);
  // eslint-disable-next-line no-nested-ternary
  // const numberOfAppsToDisplay = width > 2366 ? APP_COUNT_MAX : width > 768 ? APP_COUNT_1366 : APP_COUNT_768;

  React.useLayoutEffect(() => {
    const headingInterval = setInterval(() => {
      setHighlightedHeading((curr) => ((curr + 1) % 3) as 0 | 1 | 2);
    }, HEADING_HIGHLIGHT_DELAY);

    return () => clearInterval(headingInterval);
  }, []);

  // React.useEffect(() => {
  //   try {
  //     (async () => {
  //       setProgress(ApiProgress.Requesting);
  //       const appResponse = await getApps(APP_COUNT_MAX);
  //       const appList = appResponse.map((app) => ({
  //         name: app.name,
  //         slug: app.slug,
  //         thumbnail: app.logo || DefaultLogoSrc,
  //       }));
  //       setApps(appList);
  //       setProgress(ApiProgress.Success);
  //     })();
  //   } catch (err) {
  //     setProgress(ApiProgress.Error);
  //   } finally {
  //     setProgress(ApiProgress.Ok);
  //   }
  // }, []);

  // const navigateToProjects = (): void => {
  //   window.open(URLS.developerPortal.projects, '_blank', 'noreferrer noopener');
  // };

  // const renderShowcase = (): ReactElement => {
  //   if (progress === ApiProgress.Requesting) {
  //     return <Loader />;
  //   }
  //   return (
  //     <S.Showcase>
  //       {apps.slice(0, numberOfAppsToDisplay).map((app) => (
  //         <S.App key={app.slug} role="button" tabIndex={0} onClick={() => history.push(`${ROUTES.apps}/${app.slug}`)}>
  //           <S.AppImage src={app.thumbnail} alt={app.name} />
  //         </S.App>
  //       ))}
  //     </S.Showcase>
  //   );
  // };

  return (
    <div style={{width: '100% !important'}}>
      <S.Container>
        <S.Heading>
          <S.HeadingSegment highlighted={highlightedHeading === 0}>象寄全面升级，</S.HeadingSegment>
          <S.HeadingSegment highlighted={highlightedHeading === 1}>AI短视频翻译首发</S.HeadingSegment>
          {/* {', '}
          <S.HeadingSegment highlighted={highlightedHeading === 2}>助力跨境电商</S.HeadingSegment> */}
          {/* and{' '} */}
          {/* <S.HeadingSegment highlighted={highlightedHeading === 2}>Earn TNBC.</S.HeadingSegment> */}
        </S.Heading>
        <S.Paragraph>
          <S.SubParagraph>
            支持中英日韩欧美东南亚等多国语言，便捷的图片/短视频精修工具，支持API调用，轻松打造精品出海内容素材
          </S.SubParagraph>
          {/* <S.SubParagraph>注册即可使用</S.SubParagraph> */}
        </S.Paragraph>

        {/* <S.Actions> */}
        {/* <Button
          variant="contained"
          color="quaternary"
          onClick={() => {
            window.location.href = URLS.developerPortal.tutorials;
          }}
        >
          注册体验
        </Button> */}
        <RegisterButton />

        {/* <Button variant="outlined" onClick={navigateToProjects}>
          看看视频
        </Button> */}
        {/* </S.Actions> */}
        {/* {renderShowcase()} */}
        <CheckMark />
      </S.Container>
    </div>
  );
};

export default HomeHero;
