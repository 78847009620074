import React, {FC} from 'react';

import {Container} from 'components';

import './PrivacyPolicy.scss';

const PrivacyPolicy: FC = () => {
  return (
    <Container className="PrivacyPolicy" maxWidth={720}>
      {/*
      <section className="PrivacyPolicy__section">
        <h2 className="PrivacyPolicy__section-heading">Privacy policy</h2>
        <p className="PrivacyPolicy__text">Last updated: 11/26/2023</p>
        <p className="PrivacyPolicy__text">
          This privacy notice for("we," "us," or "our"), describes how and why we might collect, store, use, and/or
          share ("process") vour information when you use our services ("Services"), such as when you: Questions or
          concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not
          agree with our policies and practices, please do not use our Services.
        </p>
      </section>
      <section className="PrivacyPolicy__section">
        <h2 className="PrivacyPolicy__section-heading">SUMMARY OF KEY POINTS</h2>

        <p className="PrivacyPolicy__text">
          This summary provides key points from our privacy notice, but you can find out more details about any of these
          topics by clicking the link following each key point or by using our table of contents below to find the
          section you are looking for.
        </p>
        <p className="PrivacyPolicy__text">What personal information do we process?</p>
        <p className="PrivacyPolicy__text">
          When you visit, use, or navigate our Services, we may process personal information depending on how you
          interact with us and the Services, the choices you make, and the. products and features you use. Learn more
          about personal information you disclose to us.
        </p>
        <p className="PrivacyPolicy__text">Do we process any sensitive personal information?</p>
        <p className="PrivacyPolicy__text">We do not process sensitive personal information.</p>
        <p className="PrivacyPolicy__text">Do we receive any information from third parties?</p>
        <p className="PrivacyPolicy__text">
          We may receive information from public databases, marketing partners, social media platforms, and other
          outside sources. Learn more about information collected from other sources.
        </p>
        <p className="PrivacyPolicy__text">How do we process your information? </p>
        <p className="PrivacyPolicy__text">
          We process your information to provide, improve, and administer our Services, communicate with you, for
          security and fraud prevention, and to comply with law. We may also process your information for other purposes
          with your consent. We process your information only when we have a valid legal reason to do so.{' '}
        </p>
        <p className="PrivacyPolicy__text">Learn more about how we process your information</p>
        <p className="PrivacyPolicy__text">
          In what situations and with which parties do we share personal information?{' '}
        </p>
        <p className="PrivacyPolicy__text">
          We may share information in specific situations and with specific third parties.{' '}
        </p>
        <p className="PrivacyPolicy__text">Learn more about when and with whom we share your personal information. </p>
        <p className="PrivacyPolicy__text">What are your rights? </p>
        <p className="PrivacyPolicy__text">
          Depending on where you are located geographically, the applicable privacy law may mean you have certain rights
          regarding your personal information.{' '}
        </p>
        <p className="PrivacyPolicy__text">Learn more about your privacy rights How do you exercise your rights? </p>
        <p className="PrivacyPolicy__text">
          The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us.
          We will consider and act upon any request in accordance with applicable data protection laws.
        </p>
        <p className="PrivacyPolicy__text">Want to learn more about what we do with any information we collect? </p>
        <p className="PrivacyPolicy__text">Review the privacy notice in full.</p>
      </section>
      <section className="PrivacyPolicy__section">
        <h2 className="PrivacyPolicy__section-heading">TABLE OF CONTENTS</h2>
        <ul className="PrivacyPolicy__list">
          <li className="PrivacyPolicy__list-item">1. WHAT INFORMATION DO WE COLLECT?</li>
          <li className="PrivacyPolicy__list-item">2. HOW DO WE PROCESS YOUR INFORMATION?</li>
          <li className="PrivacyPolicy__list-item">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
          <li className="PrivacyPolicy__list-item">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
          <li className="PrivacyPolicy__list-item">5. HOW DO WE HANDLE YOUR SOCIALLOGINS?</li>
          <li className="PrivacyPolicy__list-item">6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</li>
          <li className="PrivacyPolicy__list-item">7. HOW LONG DO WE KEEP YOUR INFORMATION?</li>
          <li className="PrivacyPolicy__list-item">8. DO WE COLLECT INFORMATION FROM MINORS?</li>
          <li className="PrivacyPolicy__list-item">9. WHAT ARE YOUR PRIVACY RIGHTS?</li>
          <li className="PrivacyPolicy__list-item">10. CONTROLS FOR DO-NOT-TRACK FEATURES</li>
          <li className="PrivacyPolicy__list-item">11.DO WE MAKE UPDATES TO THIS NOTICE?</li>
          <li className="PrivacyPolicy__list-item">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
          <li className="PrivacyPolicy__list-item">
            13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </li>
          <li className="PrivacyPolicy__list-item">14. WHAT INFORMATION DO WE COLLECT? </li>
          <p className="PrivacyPolicy__text">
            Personal information you disclose to us In Short: We collect personal information that you provide to us. We
            collect personal information that you voluntarily provide to us when you register on the Services, express
            an interest in obtaining information about us or our products and Services, when you participate in
            activities on the Services, or otherwise when you contact us. Sensitive Information. We do not process
            sensitive information. All personal information that you provide to us must be true, complete, and accurate,
            and you must notify us of any changes to such personal information. Information automatically collected In
            Short: Some information - such as your Internet Protocol (IP) address and/or browser and device
            characteristics - is collected automatically when you visit our Services. We automatically collect certain
            information when you visit, use, or navigate the Services. This information does not reveal your specific
            identity (like your name or contact information) but may include device and usage information, such as your
            IP address, browser and device characteristics, operating system, language preferences, referring URLs,
            device name, country, location, information about how and when you use our Services, and other technical
            information. This information is primarily needed to maintain the security and operation of our Services,
            and for our internal analytics and reporting purposes. Like many businesses, we also collect information
            through cookies and similar technologies.{' '}
          </p>

          <li className="PrivacyPolicy__list-item">15. HOW DO WE PROCESS YOUR INFORMATION?</li>
          <p className="PrivacyPolicy__text">
            In Short: We process your information to provide, improve, and administer our Services, communicate with
            you, for security and fraud prevention, and to comply with law. We may also process your information for
            other purposes with your consent. We process your personal information for a variety of reasons, depending
            on how you interact with our Services, including:
          </p>

          <li className="PrivacyPolicy__list-item">16. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
          <p className="PrivacyPolicy__text">
            In Short: We may share information in specific situations described in this section and/or with the
            following third parties. We may need to share your personal information in the following situations: •
            Business Transfers. We may share or transfer your information in connection with, or during negotiations of,
            any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another
            company. • Affiliates. We may share your information with our affiliates, in which case we will require
            those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries,
            joint venture partners, or other companies that we control or that are under common control with us.
            Business Partners. We may share your information with our business partners to offer you certain products,
            services, or promotions.{' '}
          </p>

          <li className="PrivacyPolicy__list-item">17. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
          <p className="PrivacyPolicy__text">
            In Short: We may use cookies and other tracking technologies to collect and store your information. We may
            use cookies and similar tracking technologies (like web beacons and pixels) to access or store information.
            Specific information about how we use such technologies and how you can refuse certain cookies is set out in
            our Cookie Notice.
          </p>

          <li className="PrivacyPolicy__list-item">18. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
          <p className="PrivacyPolicy__text">
            In Short: If you choose to register or log in to our Services using a social media account, we may have
            access to certain information about you. Our Services offer you the ability to register and log in using
            your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do
            this, we will receive certain profile information about you from your social media provider. The profile
            information we receive may vary depending on the social media provider concerned, but will often include
            your name, email address, friends list, and profile picture, as well as other information you choose to make
            public on such a social media platform We will use the information we receive only for the purposes that are
            described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please
            note that we do not control, and are not responsible for, other uses of your personal information by your
            third-party social media provider. We recommend that you review their privacy notice to understand how they
            collect, use, and share your personal information, and how you can set your privacy preferences on their
            sites and apps.{' '}
          </p>

          <li className="PrivacyPolicy__list-item">19. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</li>
          <p className="PrivacyPolicy__text">
            In Short: We may transfer, store, and process your information. in countries other than your own. Our
            servers are located in. If you are accessing our Services from outside, please be aware that your
            information may be transferred to, stored, and processed by us in our facilities and by those third parties
            with whom we may share your personal information (see "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
            INFORMATION?" above), in and other countries. If vou are a resident in the European Economic Area (EEA),
            United Kingdom (UK), or Switzerland, then these countries may not necessarily have data protection laws or
            other similar laws as comprehensive as those in your country. However, we will take all necessary measures
            to protect your personal information in accordance with this privacy notice and applicable law.{' '}
          </p>

          <li className="PrivacyPolicy__list-item">20. HOW LONG DO WE KEEP YOUR INFORMATION?</li>
          <p className="PrivacyPolicy__text">
            In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy
            notice unless otherwise required by law. We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a longer retention period is required or
            permitted by law (such as tax, accounting, or other legal requirements). When we have no ongoing legitimate
            business need to process your personal information, we will either delete or anonymize such information, or,
            if this is not possible (for example, because your personal information has been stored in backup archives),
            then we will securely store your personal information and isolate it from any further processing until
            deletion is possible.{' '}
          </p>
          <li className="PrivacyPolicy__list-item"> 21. DO WE COLLECT INFORMATION FROM MINORS?</li>
          <p className="PrivacyPolicy__text">
            In Short: We do not knowingly collect data from or market to children under 18 years of age. We do not
            knowingly solicit data from or market to children under 18 years of age. By using the Services, you
            represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to
            such minor dependent's use of the Services. If we learn that personal information from users less than 18
            years of age has been collected, we will deactivate the account and take reasonable measures to promptly
            delete such data from our records. If you become aware of any data we may have collected from children under
            age 18, please contact us at phone number: +8617664075341, support email: bussiness@tosoiot.com
          </p>

          <li className="PrivacyPolicy__list-item">22. WHAT ARE YOUR PRIVACY RIGHTS?</li>
          <p className="PrivacyPolicy__text">
            In Short: You may review, change, or terminate your account at any time. Withdrawing your consent: If we are
            relying on your consent to process your personal information, which may be express and/or implied consent
            depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw
            your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU
            CONTACT US ABOUT THIS NOTICE?" below. However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows, will it affect the processing of your
            personal information conducted in reliance on lawful processing grounds other than consent. Account
            Information If you would at any time like to review or change the information in your account or terminate
            your account, you can: Upon your request to terminate your account, we will deactivate or delete your
            account and information from our active databases. However, we may retain some information in our files to
            prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply
            with applicable legal requirements.
          </p>
        </ul>
      </section>

      <section className="PrivacyPolicy__section">
        <ul className="PrivacyPolicy__list">
          <li className="PrivacyPolicy__list-item">23. CONTROLS FOR DO-NOT-TRACK FEATURES</li>
          <p className="PrivacyPolicy__text">
            Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT")
            feature or setting you can activate to signal your privacy preference not to have data about your online
            browsing activities monitored and collected. At this stage no uniform technology standard for recognizing
            and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals
            or any other mechanism that automatically communicates your choice not to be tracked online. If a standard
            for online tracking is adopted that we must follow in the future, we will inform you about that practice in
            a revised version of this privacy notice
          </p>
          <li className="PrivacyPolicy__list-item">24. DO WE MAKE UPDATES TO THIS NOTICE?</li>
          <p className="PrivacyPolicy__text">
            In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws. We may update
            this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date
            and the updated version will be effective as soon as it is accessible. If we make material changes to this
            privacy notice, we may notify you either by prominently posting a notice of such changes or by directly
            sending you a notification. We encourage you to review this privacy notice frequently to be informed of how
            we are protecting your information.
          </p>
          <li className="PrivacyPolicy__list-item">25. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
          <p className="PrivacyPolicy__text">
            If you have questions or comments about this notice, you may contact us by post at:
            <p className="PrivacyPolicy__text">phone number: +8617664075341 support email: bussiness@tosoiot.com</p>
          </p>
          <li className="PrivacyPolicy__list-item">
            26. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </li>
          <p className="PrivacyPolicy__text">
            Based on the applicable laws of your country, you may have the right to request access to the personal
            information we collect from you, change that information, or delete it. To request to review, update, or
            delete your personal information, please fill out and submit a data subject access request. This privacy
            policy was created using Termly's Privacy Policy Generator.
          </p>
        </ul>
      </section>

      <section className="PrivacyPolicy__section">
        <p className="PrivacyPolicy__text">phone number: +8617664075341 support email: bussiness@tosoiot.com</p>
      </section>
      */}
      <p className="PrivacyPolicy__effective">生效时间: 01-01-2021</p>
      <p className="PrivacyPolicy__last-updated">上次更改时间: 11-28-2023</p>
      <h1 className="PrivacyPolicy__heading">隐私政策</h1>
      <div className="PrivacyPolicy__main-text">
        <p className="PrivacyPolicy__text">
          象寄科技非常重视用户的隐私。
          我们希望通过《隐私政策》向你说明象寄科技如何收集、使用、披露、转让以及存储用户信息。
        </p>
      </div>
      <section className="PrivacyPolicy__section">
        <h2 className="PrivacyPolicy__section-heading">我们收集哪些个人信息</h2>
        <p className="PrivacyPolicy__text">个人信息是可用于识别或联系特定个人的数据。</p>
        <p className="PrivacyPolicy__text">
          当你注册象寄科技账号、购买产品、下载软件更新、联系我们、参与在线调查时、我们可能会收集各种信息，包括你的姓名、邮寄地址、电话号码、电子邮件地址、联系方式偏好以及银行卡号信息。
        </p>
        <p className="PrivacyPolicy__text">
          在你使用象寄科技网络服务，或访问象寄科技平台网页时，象寄科技将自动接收并记录你的浏览器和计算机上的信息，包括但不限于你的IP地址、浏览器类型、使用语言、访问日期时间、软硬件特征信息及你需求的网页记录等数据。
        </p>
        <p className="PrivacyPolicy__text">
          当你使用象寄科技产品与家人、朋友分享内容或邀请他人使用象寄科技服务时，象寄科技可能会收集你提供的与上述人士有关的信息，如姓名、邮寄地址、电子邮件地址以及电话号码。象寄科技将使用此类信息来满足你的要求，提供相关服务，或实现反欺诈目的。
        </p>
      </section>
      <section className="PrivacyPolicy__section">
        <h2 className="PrivacyPolicy__section-heading">我们如何使用个人信息</h2>
        <p className="PrivacyPolicy__text">
          利用我们收集的个人信息，我们可以将象寄科技的最新功能发布、软件更新及活动预告及时通知你。
        </p>
        <p className="PrivacyPolicy__text">
          我们还会将个人信息用于以下目的：帮助我们创建、开发、运作、提供和改进我们的产品、服务、内容和广告宣传，以及防丢失和防欺诈目的。
        </p>
        <p className="PrivacyPolicy__text">
          我们可能会使用你的个人信息（例如手机号码）来验证身份、协助识别用户，以及确定适当的服务。
        </p>
        <p className="PrivacyPolicy__text">
          我们可能会使用个人信息发送重要通知，例如关于购买的相关信息以及条款、条件和政策的变更。由于这些信息对你与象寄科技之间的沟通至关重要，你不能拒绝接收此类信息。
        </p>
        <p className="PrivacyPolicy__text">
          我们还会将个人信息用于审计、数据分析和研究等内部目的，以改进象寄科技的产品、服务和与用户之间的沟通。
        </p>
      </section>
      <section className="PrivacyPolicy__section">
        <h2 className="PrivacyPolicy__section-heading">非个人信息的收集和利用</h2>
        <p className="PrivacyPolicy__text">我们还会收集利用其本身无法与任何特定个人直接建立联系的数据。</p>
        <p className="PrivacyPolicy__text">
          我们会自动收集某些信息，将其存储在日志文件中。这类信息包括 Internet 协议 (IP) 地址、浏览器类型和语言、Internet
          服务提供商 (ISP)、引荐/退出网站和应用软件、操作系统、日期/时间戳和点击流数据。
        </p>
        <p className="PrivacyPolicy__text">
          我们也将通过 Cookie 和其他技术收集的信息视为非个人信息。Cookie
          是一个标准的互联网技术，它可以使我们存储和获得用户登录信息。
        </p>
        <p className="PrivacyPolicy__text">
          我们使用这些信息来了解和分析趋势、管理网站和产品、了解用户行为、改进我们的产品和服务，以及收集用户群的整体受众特征信息。象寄科技可将此类信息用于我们的营销和广告服务。
        </p>
      </section>
      <section className="PrivacyPolicy__section">
        <h2 className="PrivacyPolicy__section-heading">分享你的个人信息</h2>
        <p className="PrivacyPolicy__text">
          出于提供或改进我们的产品、服务和广告宣传目的，象寄科技会向与象寄科技合作提供产品和服务或者帮助象寄科技向客户进行营销的战略合作伙伴提供某些个人信息。但我们不会在未经用户许可的情况下，为第三方的营销利益将你的个人信息贩卖或共享给第三方。
        </p>
      </section>
      <section className="PrivacyPolicy__section">
        <h2 className="PrivacyPolicy__section-heading">个人信息安全</h2>
        <p className="PrivacyPolicy__text">
          象寄科技非常重视用户个人信息安全。
          我们使用各种安全技术和程序保障你发送给我们的数据的安全性，以防信息的丢失、不当使用、未经授权阅览或披露。
        </p>
        <p className="PrivacyPolicy__text">
          根据互联网的发展和有关法律、法规及规范性文件的变化，或者因业务发展需要，象寄科技可对本政策的条款作出修改或变更，一旦本政策的内容发生变动，象寄科技将会直接在象寄科技网站上公布修改之后的政策内容，该公布行为视为象寄科技已经通知用户修改内容。
        </p>
      </section>
      <section className="PrivacyPolicy__section">
        <h2 className="PrivacyPolicy__section-heading">联系我们</h2>
        <p className="PrivacyPolicy__text">
          用户也可以在任何时间访问和查看最新版本的隐私政策（点击象寄科技首页最下方的“隐私政策”即可）。若你对本《隐私政策》或相关事宜有任何问题，请通过
          business@tosoiot.com 与我们联系。
        </p>
      </section>
    </Container>
  );
};

export default PrivacyPolicy;
