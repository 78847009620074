// eslint-disable-next-line prettier/prettier
import React, {FC} from 'react';

// eslint-disable-next-line prettier/prettier
import {Container} from 'components';

import './ContactUsEn.scss';

const ContactUsEn: FC = () => {
  return (
    <Container className="TermsOfUse" maxWidth={720}>
      {/* <h1 className="TermsOfUse__heading">Contact us</h1>
      <div className="TermsOfUse__main-text" />
      <section className="TermsOfUse__section">
        <p className="TermsOfUse__text">You Can Also Contact Us Here:</p>
        <p className="TermsOfUse__text">Company: Suzhou XiangJi Technology Service Co. Ltd</p>
        <p className="TermsOfUse__text">
          Address: Room 102, Building 3, Taihu New City Science and Technology Park, No. 18 Suzhou River Road, East
          Taihu Lake Ecotourism Resort (Taihu New City), Wujiang District, Suzhou City, Jiangsu Province, P.R.C.
        </p>
        <p className="TermsOfUse__text">Customer Service (8AM - 6PM CST, 7 days a week):</p>
        <p className="TermsOfUse__text">phone number: +8617664075341</p>
        <p className="TermsOfUse__text">support email: bussiness@tosoiot.com</p>
      </section> */}
      <h1 className="TermsOfUse__heading">联系我们</h1>
      <div className="TermsOfUse__main-text" />
      <section className="TermsOfUse__section">
        <p className="TermsOfUse__text">苏州象寄科技服务有限公司</p>
        <p className="TermsOfUse__text">苏州总部：苏州市吴江区东太湖生态旅游度假区（太湖新城）苏州河路18号</p>
        <p className="TermsOfUse__text">地址: 上海市浦东新区纳贤路800号A座228室</p>
        <p className="TermsOfUse__text">周一至周六，上午: 9:00 – 下午 18:00(周日及节假日除外)</p>
        <p className="TermsOfUse__text">电话号码: +8617664075341</p>
        <p className="TermsOfUse__text">支持邮箱: bussiness@tosoiot.com</p>
      </section>
    </Container>
  );
};

export default ContactUsEn;
