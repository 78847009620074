import React from 'react';
// import {useHistory} from 'react-router';

// import {ROUTES, URLS} from 'constants/routes';
// import WalletImage from 'assets/images/placeholder.png';

import * as S from './Styles';

const Brush = () => {
  // const history = useHistory();

  return (
    <S.Wrapper>
      <S.Container>
        <S.Content>
          <S.Heading>AI翻译，视频剪辑</S.Heading>
          <S.Paragraph>
            简易便捷的视频翻译剪辑页面，可对翻译后的视频，进行字幕样式、人声合成、背景音乐等重新设计。
          </S.Paragraph>
          {/* <S.A href={URLS.tnbFaucet}>免费</S.A> */}
          {/* <S.Button variant="outlined" onClick={() => history.push(ROUTES.download)}>
            Download Wallet
          </S.Button> */}
        </S.Content>
        <S.ImageContainer>
          <S.Video
            className="lazy"
            muted
            // preload
            // poster={WalletImage}
            poster="https://i.tosoiot.com/static/video/视频翻译引导动画2.png"
            data-src="https://i.tosoiot.com/static/video/新视频翻译动画2.mp4"
            autoPlay
            loop
          />
        </S.ImageContainer>
      </S.Container>
    </S.Wrapper>
  );
};

export default Brush;
