import React, {FC} from 'react';

const Jingxiu: FC = () => {
  return (
    <img
      id="JingxiuLogo"
      alt="图片精修"
      width="160"
      height="160"
      src="https://i.tosoiot.com/static/images/jingxiu.png"
    />
  );
};

export default Jingxiu;
