import React, {FC} from 'react';

import {CustomIconProps} from './types';

const ProjectsIcon: FC<CustomIconProps> = ({size = 24, state = 'default'}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#faqProjectsClip0)">
        <path
          d="M28.1538 27.6166C27.3217 28.4598 26.5771 28.9554 26.007 29.3296C20.4474 32.9788 14.0921 31.927 12.1272 31.5675C8.95711 30.9875 6.28116 30.5783 3.98189 28.5363C-1.24253 23.8965 -0.988403 14.2171 3.00368 8.22122C3.47707 7.51032 7.46309 1.70197 13.8321 0.883519C13.9879 0.863516 14.104 0.852053 14.2059 0.84149C15.1912 0.738889 21.2967 0.197678 26.2752 4.29789C26.9878 4.88484 28.1347 5.94344 29.1982 7.55808C33.0868 13.462 33.0991 22.6056 28.1538 27.6166Z"
          fill="url(#faqProjectsPaint0_linear)"
        />
        <path
          d="M2.57262 17.1928C2.57262 17.1928 3.27925 16.4397 4.80267 18.1847C6.327 19.9295 7.00202 20.984 7.00202 20.984C7.00202 20.984 7.37721 21.3361 7.59978 20.9933C7.82235 20.6505 8.02749 19.7817 8.55579 19.8022C9.10367 19.8237 9.41215 20.3473 9.52103 21.1997C9.62991 22.052 9.93727 23.9096 9.96589 24.078C9.99337 24.2428 10.1475 24.459 10.3369 24.2494C10.5264 24.0397 11.479 22.2833 12.2971 22.781C13.1162 23.2784 13.0235 25.7008 13.0186 25.9655C13.0136 26.2301 13.0891 26.3252 13.2241 26.3582C13.3591 26.3912 13.6231 25.7707 13.9143 25.2819C14.2055 24.7932 14.6311 24.2776 15.0791 24.6036C15.5272 24.9297 16.0611 26.6023 15.1098 29.7551L14.572 31.1872L13.0428 31.467C9.73298 31.8689 8.14887 31.0719 7.89758 30.582C7.64628 30.092 8.23216 29.7639 8.76943 29.5615C9.30671 29.3591 9.9707 29.2053 9.9601 29.0684C9.94951 28.9316 9.86776 28.8424 9.60207 28.8031C9.33639 28.7638 6.89966 28.452 6.53802 27.5725C6.17666 26.6938 8.09081 26.0588 8.33138 25.9086C8.57223 25.7594 8.3813 25.5743 8.22132 25.5199C8.05798 25.4645 6.25085 24.8573 5.41703 24.6095C4.58349 24.3626 4.11101 23.975 4.18051 23.4386C4.24684 22.9211 5.15075 22.8665 5.53028 22.7071C5.90981 22.5477 5.62038 22.1234 5.62038 22.1234C5.62038 22.1234 4.67785 21.2913 3.18655 19.5195C1.69525 17.7477 2.58981 17.1766 2.58981 17.1766"
          fill="#33C27F"
        />
        <path
          d="M28.0783 22.2499C28.0783 22.2499 27.7733 21.474 26.0938 22.3447C24.4137 23.2148 23.5843 23.813 23.5843 23.813C23.5843 23.813 23.1942 23.9688 23.1301 23.6447C23.0661 23.3206 23.1747 22.6045 22.7696 22.465C22.3495 22.3206 21.9578 22.6254 21.617 23.237C21.2763 23.8485 20.4808 25.1609 20.4081 25.2796C20.3374 25.3959 20.1554 25.514 20.076 25.3001C19.9966 25.0863 19.8101 23.4808 19.0415 23.6166C18.2722 23.7519 17.6074 25.6079 17.5309 25.8092C17.4544 26.0105 17.3685 26.0601 17.2566 26.0454C17.1446 26.0307 17.1336 25.4848 17.062 25.0304C16.9904 24.576 16.8254 24.0619 16.3883 24.1767C15.9511 24.2914 15.0406 25.3976 14.8026 28.0569L14.7742 29.2959L15.8439 29.9557C18.2209 31.23 19.6586 31.0928 19.9969 30.7966C20.3353 30.5005 19.9925 30.0808 19.6482 29.7705C19.304 29.4601 18.8493 29.1492 18.8988 29.049C18.9483 28.9488 19.0371 28.9054 19.2496 28.9537C19.4621 29.0019 21.3964 29.4813 21.9362 28.9233C22.4755 28.3659 21.223 27.3251 21.0869 27.1411C20.9503 26.9578 21.1506 26.874 21.2879 26.8799C21.428 26.886 22.9766 26.9576 23.6813 27.0151C24.3854 27.0732 24.8597 26.9191 24.97 26.4937C25.0768 26.0836 24.411 25.7772 24.1728 25.5456C23.9346 25.3139 24.2818 25.0785 24.2818 25.0785C24.2818 25.0785 25.2458 24.7267 26.9091 23.8264C28.5724 22.9262 28.0703 22.2326 28.0703 22.2326"
          fill="#33C27F"
        />
        <path
          d="M6.81445 13.8636C6.81445 8.48896 11.1996 4.13062 16.6099 4.13062C22.0189 4.13062 26.4052 8.48785 26.4052 13.8636C26.4052 16.0061 25.7077 17.9865 24.5273 19.5947C23.1289 21.6884 19.496 24.0519 19.496 25.4681V27.0852C19.496 27.4084 19.2322 27.6695 18.9081 27.6695H17.3331H15.8866H14.3127C13.9875 27.6695 13.7248 27.4073 13.7248 27.0852V25.4681C13.7248 24.0519 10.0919 21.6884 8.6935 19.5947C7.51197 17.9876 6.81445 16.0072 6.81445 13.8636Z"
          fill="#3A97D4"
        />
        <path
          d="M17.722 27.5085C17.7108 27.2641 17.5353 23.3578 17.8505 19.9336C17.4649 19.9791 17.0513 20.0035 16.6098 20.0035C16.1682 20.0035 15.7558 19.9791 15.369 19.9336C15.6842 23.3578 15.5087 27.2641 15.4964 27.5085L15.3232 27.5007C15.3254 27.4407 15.5076 23.4 15.1857 19.9102C12.2805 19.5093 10.9805 17.8732 10.7468 16.3305C10.6105 15.4286 10.9201 14.5312 11.4835 14.1957C11.8255 13.9925 12.238 13.9514 12.6784 14.0769C13.3703 14.2746 14.0276 14.8666 14.4367 15.6618C14.9096 16.5804 15.189 18.1042 15.35 19.7592C15.7401 19.8069 16.1582 19.8325 16.6064 19.8325C17.0558 19.8325 17.4738 19.8069 17.8628 19.7592C18.0238 18.1042 18.3033 16.5804 18.7761 15.6618C19.1852 14.8666 19.8436 14.2746 20.5344 14.0769C20.9737 13.9514 21.3873 13.9925 21.7294 14.1957C22.2927 14.5312 22.6035 15.4286 22.466 16.3305C22.2324 17.8743 20.9324 19.5093 18.0272 19.9102C17.7052 23.3989 17.8874 27.4396 17.8897 27.5007L17.722 27.5085ZM21.024 14.178C20.8843 14.178 20.7379 14.1991 20.5869 14.2424C19.9397 14.4268 19.3227 14.9865 18.9348 15.7396C18.4799 16.6226 18.2082 18.1098 18.0495 19.7347C20.8329 19.336 22.0781 17.7777 22.3006 16.3049C22.4246 15.4819 22.1429 14.6389 21.6455 14.3435C21.46 14.2335 21.2498 14.178 21.024 14.178ZM12.1955 14.178C11.9697 14.178 11.7596 14.2335 11.574 14.3435C11.0766 14.6389 10.7949 15.483 10.919 16.3049C11.1414 17.7766 12.3878 19.336 15.17 19.7347C15.0113 18.1098 14.7397 16.6226 14.2847 15.7396C13.8968 14.9865 13.2787 14.4268 12.6326 14.2424C12.4828 14.2002 12.3364 14.178 12.1955 14.178Z"
          fill="#D6ECFF"
        />
        <path
          d="M24.7739 14.4223C24.4632 14.4223 24.2117 14.1724 24.2117 13.8636C24.2117 10.4004 21.8553 7.38937 18.4818 6.5408C18.1811 6.46528 17.9989 6.16095 18.0749 5.86217C18.1509 5.5634 18.4572 5.38235 18.7579 5.45788C22.6311 6.43196 25.3362 9.88842 25.3362 13.8636C25.3351 14.1724 25.0836 14.4223 24.7739 14.4223Z"
          fill="#D6ECFF"
        />
        <path
          d="M16.4209 5.73666C16.4209 6.04543 16.6724 6.29533 16.9832 6.29533C17.2939 6.29533 17.5454 6.04543 17.5454 5.73666C17.5454 5.42788 17.2939 5.17798 16.9832 5.17798C16.6724 5.17798 16.4209 5.42788 16.4209 5.73666Z"
          fill="#D6ECFF"
        />
        <g opacity="0.55">
          <g opacity="0.55">
            <g opacity="0.55">
              <path
                opacity="0.55"
                d="M26.3858 14.4401C25.8448 18.4308 22.6031 22.5625 16.6094 22.5625C10.6168 22.5625 7.49475 18.2375 6.83301 14.4401C6.94591 16.3616 7.61771 18.1331 8.69193 19.5948C10.0903 21.6884 13.7232 24.052 13.7232 25.4681V27.0853C13.7232 27.4085 13.987 27.6695 14.3112 27.6695H15.8851H17.3315H18.9054C19.2307 27.6695 19.4934 27.4074 19.4934 27.0853V25.4681C19.4934 24.052 23.1263 21.6884 24.5246 19.5948C25.6 18.132 26.2729 16.3604 26.3858 14.4401Z"
                fill="#FFC043"
              />
            </g>
          </g>
        </g>
        <path
          d="M19.7274 26.1589H13.4922V29.1111C13.4922 29.5932 13.6308 30.0652 13.8901 30.4729C14.382 31.2426 15.2349 31.7079 16.1526 31.7079H17.067C17.9847 31.7079 18.8376 31.2415 19.3294 30.4729C19.5899 30.0663 19.7274 29.5932 19.7274 29.1111V26.1589Z"
          fill="#28364C"
        />
        <path
          d="M13.4918 28.1493C13.5097 28.1504 13.5276 28.1493 13.5455 28.1471L19.7795 27.5051C19.9919 27.4829 20.1406 27.293 20.1115 27.0808C20.0824 26.8687 19.8846 26.7132 19.6722 26.7354L13.4382 27.3785C13.2258 27.4007 13.0771 27.5906 13.1062 27.8028C13.1341 27.9971 13.3007 28.1426 13.4918 28.1493Z"
          fill="#475577"
        />
        <path
          d="M13.492 29.501C13.5098 29.5021 13.5277 29.501 13.5456 29.4988L19.7797 28.8568C19.992 28.8346 20.1407 28.6447 20.1116 28.4326C20.0826 28.2204 19.8847 28.066 19.6723 28.0871L13.4372 28.7291C13.2248 28.7513 13.0761 28.9413 13.1052 29.1534C13.1343 29.3489 13.3008 29.4944 13.492 29.501Z"
          fill="#475577"
        />
        <path
          d="M13.7418 30.8382C13.7597 30.8393 13.7776 30.8382 13.7955 30.836L19.7053 30.2273C19.9177 30.2051 20.0664 30.0152 20.0373 29.803C20.0083 29.5909 19.8104 29.4354 19.598 29.4576L13.6882 30.0663C13.4758 30.0885 13.3271 30.2784 13.3562 30.4906C13.383 30.686 13.5507 30.8315 13.7418 30.8382Z"
          fill="#475577"
        />
        <path
          d="M16.6104 0V3.05884"
          stroke="#067AB8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.161 1.21844L20.918 4.01627"
          stroke="#067AB8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.8392 4.60156L24.5645 6.663"
          stroke="#067AB8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.0586 1.21844L12.3016 4.01627"
          stroke="#067AB8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.38086 4.60156L8.65561 6.663"
          stroke="#067AB8"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {state === 'default' && (
          <g style={{mixBlendMode: 'saturation'}}>
            <rect y="-0.299377" width="32" height="32" fill="white" />
          </g>
        )}
      </g>
      <defs>
        <linearGradient
          id="faqProjectsPaint0_linear"
          x1="35.3012"
          y1="36.7186"
          x2="7.44065"
          y2="7.65041"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DAE3FE" />
          <stop offset="1" stopColor="#E9EFFD" />
        </linearGradient>
        <clipPath id="faqProjectsClip">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProjectsIcon;
