import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {GoToTop} from 'components';
import Footer from 'containers/Footer';
import FriendshipLinks from 'containers/FriendshipLinks';
import {SFC} from 'types/generic';

import * as S from './Styles';

const Layout: SFC = ({children}) => {
  const {hash, pathname} = useLocation();

  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
    } else {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [hash, pathname]);

  const renderCopyright = () => {
    return (
      <S.CopyrightContainer>
        {window.location.host === 'www.tosoiot.com' ? (
          <ul>
            <S.CopyrightLi>
              版权所有 山东象寄科技服务有限公司
              <a target="_blank" rel="noreferrer" href="http://beian.miit.gov.cn">
                鲁ICP备2021031214号-1
              </a>
            </S.CopyrightLi>
            <S.CopyrightLi>Copyright ©2023 www.tosoiot.com, All Rights Reserved.</S.CopyrightLi>
            <S.CopyrightLi>
              <img
                alt="gongan"
                src="https://www.xiangjifanyi.com/website/images/gwab.png"
                style={{verticalAlign: 'top'}}
              />
              公网安备
            </S.CopyrightLi>
          </ul>
        ) : (
          <ul>
            <S.CopyrightLi>
              版权所有 苏州象寄科技服务有限公司
              <a target="_blank" rel="noreferrer" href="http://beian.miit.gov.cn">
                苏ICP备2023007092号-3
              </a>
            </S.CopyrightLi>
            <S.CopyrightLi>Copyright ©2023 www.xiangjifanyi.com, All Rights Reserved.</S.CopyrightLi>
            <S.CopyrightLi>
              <img
                alt="gongan"
                src="https://www.xiangjifanyi.com/website/images/gwab.png"
                style={{verticalAlign: 'top'}}
              />
              公网安备
            </S.CopyrightLi>
          </ul>
        )}
      </S.CopyrightContainer>
    );
  };
  return (
    <S.Layout data-testid="Layout">
      <S.LayoutTopNavWrapper>
        <S.LayoutTopNav />
      </S.LayoutTopNavWrapper>
      <S.LayoutContent data-testid="Layout__content">{children}</S.LayoutContent>
      <FriendshipLinks />
      <S.LayoutFooterWrapper>
        <GoToTop />
        <S.LayoutFooter>
          <Footer />
        </S.LayoutFooter>
      </S.LayoutFooterWrapper>
      {renderCopyright()}
    </S.Layout>
  );
};

export default Layout;
