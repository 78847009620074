export enum SocialMedia {
  discord = 'discord',
  facebook = 'facebook',
  gaming = 'gaming',
  github = 'github',
  instagram = 'instagram',
  linkedin = 'linkedin',
  pinterest = 'pinterest',
  reddit = 'reddit',
  twitch = 'twitch',
  twitter = 'twitter',
  youtube = 'youtube',
}
