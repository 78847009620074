import React, {FC} from 'react';
import './style.scss';
import useQueryParams from 'hooks/useQueryParams';

const RegisterButton: FC = () => {
  const queryParams = useQueryParams();
  const utmSource = queryParams.get('utm_source');
  const query = utmSource ? `?utm_source=${utmSource}` : '';
  return (
    <div>
      <a href={`https://xiangjifanyi.com/console/workspace${query}`} className="large-cta">
        开始使用
      </a>
    </div>
  );
};
export default RegisterButton;
