import React, {FC, ReactNode, useState} from 'react';
// import {useHistory} from 'react-router-dom';
import {Icon, IconType} from '@thenewboston/ui';

import {ROUTES, URLS} from 'constants/routes';
import TnbLogo from '../../../assets/svgs/TnbLogo';
// import DiscordLogo from '../../../assets/svgs/DiscordLogo';

import * as S from './Styles';

interface ComponentProps {
  closeMenu(): void;
  menuOpen: boolean;
  toggleMenu(): void;
}

type SectionStrings = 'developers' | 'getProduct' | 'resources' | 'proxy';

const TopNavMobileMenu: FC<ComponentProps> = ({closeMenu, menuOpen, toggleMenu}) => {
  // const history = useHistory();
  const [openSection, setOpenSection] = useState<SectionStrings | null>(null);

  const handleColumnTitleClick = (section: SectionStrings) => (): void => {
    setOpenSection(openSection === section ? null : section);
  };

  const renderColumn = (section: SectionStrings, title: string, links: ReactNode): ReactNode => {
    const isOpened = openSection === section;
    return (
      <S.LinkSection>
        <S.LinkSectionTitleButton onClick={handleColumnTitleClick(section)}>
          <S.LinkSectionTitle isOpened={isOpened}>{title}</S.LinkSectionTitle>
          <S.DropdownIcon isOpened={isOpened} icon={IconType.chevronDown} />
        </S.LinkSectionTitleButton>
        <S.ListOfLinks>{openSection === section ? links : null}</S.ListOfLinks>
      </S.LinkSection>
    );
  };

  const renderMenu = (): ReactNode => {
    return (
      <>
        <S.DropdownContainer>
          {renderColumn(
            'getProduct',
            '产品服务',
            <>
              {/* {renderMobileLink('价格', ROUTES.price)} */}
              {renderMobileLink('视频翻译', URLS.videoTrans, true, true)}
              {renderMobileLink('图片翻译', URLS.imgTrans, true, true)}
              {renderMobileLink('使用指南', URLS.guide, true, true)}
              {renderMobileLink('支持语言', ROUTES.language)}
              {/* {renderMobileLink('常见问题', URLS.developerPortal.projects, true, false)} */}
              {/* // {renderMobileLink('Apps', ROUTES.apps)} */}
            </>,
          )}
          {renderColumn(
            'developers',
            'API对接',
            <>
              {renderMobileLink('API对接文档', URLS.apiDoc, true, true)}
              {renderMobileLink('精修工具对接文档', URLS.jingxiuDoc, true, true)}
            </>,
          )}
          {renderColumn('resources', '联系我们', <>{renderMobileLink('联系我们', ROUTES.contact)}</>)}
          <S.ButtonsContainer>
            {/* <S.DownloadButton onClick={() => history.push(ROUTES.download)} variant="outlined">
              代理商入口
            </S.DownloadButton> */}
            <S.AppButton
              onClick={() => {
                window.location.href = URLS.login;
              }}
            >
              {/* <DiscordLogo style={{marginRight: '8px'}} /> */}
              <TnbLogo style={{marginRight: '8px'}} />
              开始使用
            </S.AppButton>

            {/* <S.AppButton onClick={() => history.push(ROUTES.apps)}>
              <TnbLogo style={{marginRight: '8px'}} />
              Apps
            </S.AppButton> */}
          </S.ButtonsContainer>
        </S.DropdownContainer>
        <S.Overlay onClick={closeMenu} role="button" tabIndex={0} />
      </>
    );
  };

  const renderMobileLink = (label: string, to: string, isExternal?: boolean, newWindow = false): ReactNode => {
    if (isExternal) {
      return (
        <S.ExternalLink href={to} showNewWindowIcon={false} newWindow={newWindow}>
          {label}
        </S.ExternalLink>
      );
    }
    return <S.InternalLink to={to}>{label}</S.InternalLink>;
  };

  return (
    <S.Container>
      <S.MenuButton onClick={toggleMenu}>
        <Icon icon={IconType.menu} size={24} />
      </S.MenuButton>
      {menuOpen && renderMenu()}
    </S.Container>
  );
};

export default TopNavMobileMenu;
