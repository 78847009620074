// Color design system taken from figma here:
// https://www.figma.com/file/6AGSvP6DJIIvy5Ayyp1a6U/Design-System?node-id=0%3A1

/* eslint-disable sort-keys */
const colors = {
  background: '#f1f6fa',
  black: '#000',
  secondary: '#556cd6',
  tertiary: '#1ea672',
  quaternary: '#0085ff',
  primary: '#2c3967',
  progressBar: '#17cead',
  white: '#fff',
  alert: '#ed5f74',
  danger: '#d30c15',
  github: '#0366d6',
  discord: '#5865f2',
  discordHover: '#3441ce',
  palette: {
    neutral: {
      '050': '#f7fafc',
      '075': '#f1f6fa',
      '100': '#e2e9ef',
      '200': '#c1cad2',
      '300': '#a3afb9',
      '400': '#8796a2',
      '500': '#697986',
      '600': '#4f5e6b',
      '700': '#3c4b57',
      '800': '#2a3945',
      '900': '#1a2936',
    },
    gray: {
      '005': '#ededed',
      '050': '#f7fafc',
      '075': '#eef2f6',
      '100': '#e3e8ee',
      '200': '#c1c9d2',
      '300': '#a3acb9',
      '400': '#8792a2',
      '500': '#697386',
      '600': '#4f566b',
      '700': '#3c4257',
      '800': '#2a2f45',
      '900': '#1a1f36',
    },
    blue: {
      '050': '#f5fbff',
      '100': '#d6ecff',
      '200': '#a4cdfe',
      '300': '#7dabf8',
      '400': '#6c8eef',
      '500': '#556cd6',
      '600': '#3d4eac',
      '700': '#2f3d89',
      '800': '#212d63',
      '900': '#131f41',
    },
    cyan: {
      '050': '#edfdfd',
      '100': '#c4f1f9',
      '200': '#7fd3ed',
      '300': '#4db7e8',
      '400': '#3a97d4',
      '500': '#067ab8',
      '600': '#075996',
      '700': '#06457a',
      '800': '#093353',
      '900': '#042235',
    },
    green: {
      '050': '#efffed',
      '100': '#cbf4c9',
      '200': '#85d996',
      '300': '#33c27f',
      '400': '#1ea672',
      '500': '#09825d',
      '600': '#0e6245',
      '700': '#0d4b3b',
      '800': '#0b3733',
      '900': '#082429',
    },
    yellow: {
      '050': '#fcf9e9',
      '100': '#f8e5b9',
      '200': '#efc078',
      '300': '#e5993e',
      '400': '#d97917',
      '500': '#bb5504',
      '600': '#983705',
      '700': '#762b0b',
      '800': '#571f0d',
      '900': '#3a1607',
    },
    orange: {
      '050': '#fffaee',
      '100': '#fee3c0',
      '200': '#f8b886',
      '300': '#f5925e',
      '400': '#e56f4a',
      '500': '#c44c34',
      '600': '#9e2f28',
      '700': '#7e1e23',
      '800': '#5d161b',
      '900': '#420e11',
    },
    red: {
      '050': '#fff8f5',
      '100': '#fde2dd',
      '200': '#fbb5b2',
      '300': '#fa8389',
      '400': '#ed5f74',
      '500': '#cd3d64',
      '600': '#a41c4e',
      '700': '#80143f',
      '800': '#5e1039',
      '900': '#420828',
    },
    purple: {
      '050': '#fff8fe',
      '100': '#fce0f6',
      '200': '#f0b4e4',
      '300': '#e28ddc',
      '400': '#c96ed0',
      '500': '#a450b5',
      '600': '#7b3997',
      '700': '#5b2b80',
      '800': '#401d6a',
      '900': '#2d0f55',
    },
    violet: {
      '050': '#f8f9fe',
      '100': '#e6e6fc',
      '200': '#c7c2ea',
      '300': '#b0a1e1',
      '400': '#9c82db',
      '500': '#8260c3',
      '600': '#61469b',
      '700': '#4b3480',
      '800': '#352465',
      '900': '#1f184e',
    },
  },
};

export default colors;
