import React from 'react';
// import {useHistory} from 'react-router';

// import {ROUTES, URLS} from 'constants/routes';
// import WalletImage from 'assets/images/placeholder.png';

import * as S from './Styles';

const Brush = () => {
  // const history = useHistory();

  return (
    <S.Wrapper>
      <S.Container>
        <S.Content>
          <S.Heading>手工背景擦除，局部取消翻译等特色功能</S.Heading>
          <S.Paragraph>解决产品Logo、T恤印花、产品印花等过度翻译问题；极端图片手工清理背景</S.Paragraph>
          {/* <S.A href={URLS.tnbFaucet}>免费</S.A> */}
          {/* <S.Button variant="outlined" onClick={() => history.push(ROUTES.download)}>
            Download Wallet
          </S.Button> */}
        </S.Content>
        <S.ImageContainer>
          <S.Video
            className="lazy"
            muted
            // preload
            poster="https://i.tosoiot.com/static/images/brushPoster.jpg"
            data-src="https://i.tosoiot.com/static/images/brush.mp4"
            autoPlay
            loop
          />
        </S.ImageContainer>
      </S.Container>
    </S.Wrapper>
  );
};

export default Brush;
