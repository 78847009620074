import React, {FC} from 'react';

const ImageTrans: FC = () => {
  return (
    <img
      id="imgTransLogo"
      alt="图片翻译"
      width="160"
      height="160"
      src="https://i.tosoiot.com/static/images/imgTrans.png"
    />
  );
};

export default ImageTrans;
