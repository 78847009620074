import React, {FC} from 'react';
import './style.scss';

const RegisterButton: FC = () => {
  return (
    <div className="checkmarks">
      <ul>
        <li>手机号注册</li>
        <li>大量免费额度</li>
        <li>支持DeepL、谷歌、Papago、阿里等多家翻译引擎</li>
        <li>已对接多家ERP</li>
      </ul>
    </div>
  );
};
export default RegisterButton;
