import {ROUTES, URLS} from 'constants/routes';
import {TopNavPopoverItemType} from 'containers/TopNav/TopNavPopover';

export const developerPopoverItems: TopNavPopoverItemType[] = [
  {
    isExternal: true,
    newWindow: true,
    title: 'API对接文档',
    to: URLS.apiDoc,
  },
  {
    isExternal: true,
    newWindow: true,
    title: '精修工具对接文档',
    to: URLS.jingxiuDoc,
  },

  // {
  //   isExternal: true,
  //   newWindow: false,
  //   title: 'ERP合作列表',
  //   to: ROUTES.erp,
  // },
  // {
  //   isExternal: true,
  //   newWindow: false,
  //   title: 'Tutorials',
  //   to: URLS.developerPortal.tutorials,
  // },
  // {
  //   isExternal: true,
  //   newWindow: false,
  //   title: 'Projects',
  //   to: URLS.developerPortal.projects,
  // },
  // {
  //   isExternal: true,
  //   newWindow: false,
  //   title: 'APIs',
  //   to: URLS.developerPortal.api,
  // },
  // {
  //   isExternal: true,
  //   newWindow: false,
  //   title: 'Node Deployment',
  //   to: URLS.developerPortal.nodeDeployment,
  // },
  // {
  //   isExternal: true,
  //   newWindow: false,
  //   title: 'SDKs &  Libraries',
  //   to: URLS.developerPortal.sdkAndLibraries,
  // },
  // {
  //   isExternal: true,
  //   newWindow: false,
  //   title: 'Utilities',
  //   to: URLS.developerPortal.utilities,
  // },
];

export const getProductPopoverItems: TopNavPopoverItemType[] = [
  // {
  //   title: '价格',
  //   to: ROUTES.price,
  // },
  {
    isExternal: true,
    newWindow: false,
    title: '图片翻译',
    to: URLS.imgTrans,
  },
  {
    isExternal: true,
    newWindow: false,
    title: '视频翻译',
    to: URLS.videoTrans,
  },
  {
    isExternal: true,
    newWindow: false,
    title: '使用指南',
    to: URLS.guide,
  },
  {
    title: '支持语言',
    to: ROUTES.language,
  },
  // {
  //   isExternal: true,
  //   newWindow: false,
  //   title: '代理机制',
  //   to: ROUTES.useWeb,
  // },
  // {
  //   isExternal: true,
  //   newWindow: false,
  //   title: '常见问题',
  //   to: ROUTES.faq,
  // },
];

export const getProxyPopoverItems: TopNavPopoverItemType[] = [
  {
    isExternal: true,
    newWindow: false,
    title: '代理合作方式',
    to: URLS.proxyMechanism,
  },
];
export const resourcesPopoverItems: TopNavPopoverItemType[] = [
  {
    title: '联系我们',
    to: ROUTES.contact,
  },
  // {
  //   title: '团队介绍',
  //   to: ROUTES.contact,
  // },
  // {
  //   title: 'FAQ',
  //   to: ROUTES.faq,
  // },
  // {
  //   isExternal: true,
  //   newWindow: true,
  //   title: 'Blog',
  //   to: URLS.blog,
  // },
  // {
  //   title: 'Media Kit',
  //   to: ROUTES.assets,
  // },
  // {
  //   title: 'Meet the Team',
  //   to: ROUTES.teams,
  // },
  // {
  //   title: 'About Us',
  //   to: ROUTES.aboutUs,
  // },
  // {
  //   title: 'Join the Community',
  //   to: ROUTES.social,
  // },
  // {
  //   title: 'Donate',
  //   to: ROUTES.donate,
  // },
];
