import React, {FC} from 'react';

import {Container} from 'components';

import './ReturnPolicy.scss';

const ReturnPolicy: FC = () => {
  return (
    <Container className="TermsOfUse" maxWidth={720}>
      <p className="TermsOfUse__effective">生效时间: 01-01-2021</p>
      <p className="TermsOfUse__last-updated">上次更改时间: 11-28-2022</p>
      <h1 className="TermsOfUse__heading">退货和退款政策 </h1>
      <p className="TermsOfUse__text">
        感谢您选择象寄服务。我们希望确保您对我们的服务感到满意。请查看以下我们的退货/退款政策：
      </p>
      <div className="TermsOfUse__main-text" />
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">1. 退款资格： </h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">我们为 SaaS 产品提供 30 天退款保证。</li>
          <li className="TermsOfUse__list-item">要获得退款资格，您必须在首次购买后 30 天内申请退款。</li>
        </ul>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">2. 退款流程： </h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">
            如需申请退款，请联系我们的客户支持团队：
            <p className="TermsOfUse__text">电话号码: +8617664075341</p>
            <p className="TermsOfUse__text">支持邮箱: bussiness@tosoiot.com</p>
          </li>
          <li className="TermsOfUse__list-item">提供您的订单详细信息和退款请求的原因。</li>
          <li className="TermsOfUse__list-item">
            我们的团队将审核您的请求并在 3 个工作日内回复您。退款将在批准后的 7-15 天内通过原始付款方式记入银行账户。
          </li>
        </ul>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">3. 退款条件： </h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">退款仅适用于首次购买我们的 SaaS 产品。</li>
          <li className="TermsOfUse__list-item">任何额外的购买、升级或续订均不符合退款条件。</li>
          <li className="TermsOfUse__list-item">退款将使用与原始购买相同的付款方式发放。</li>
        </ul>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">4. 退款例外： </h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">
            对于支付给与我们的 SaaS 产品集成的第三方服务或平台的任何费用，我们不予退款。
          </li>
          <li className="TermsOfUse__list-item">如果有证据表明误用、滥用或违反我们的服务条款，将不予退款。</li>
        </ul>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">5. 取消订阅：</h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">如果您决定在最初的 30 天退款期后取消订阅，您将没有资格获得退款。</li>
          <li className="TermsOfUse__list-item">但是，您可以随时取消订阅，并且不会自动续订。</li>
        </ul>
        <p className="TermsOfUse__text">请注意，本退货和退款政策如有更改，恕不另行通知。我们建议定期查看它。</p>
        <p className="TermsOfUse__text">如果您有任何问题或需要进一步的帮助，请随时联系我们的客户支持团队。</p>
        <p className="TermsOfUse__text">电话号码: +8617664075341</p>
        <p className="TermsOfUse__text">支持邮箱: bussiness@tosoiot.com</p>
      </section>

      {/* <p className="TermsOfUse__effective">Effective time: 01/01/2021</p>
      <p className="TermsOfUse__last-updated">Last updated: 11/26/2023</p>
      <h1 className="TermsOfUse__heading">Return and Refund Policy</h1>
      <p className="TermsOfUse__text">
        Thank you for choosing XiangJi service. We want to ensure your satisfaction with our services. Please review our
        Return and Refund Policy below:
      </p>
      <div className="TermsOfUse__main-text" />
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">1. Refund Eligibility:</h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">We offer a 30-day money-back guarantee for our SaaS product.</li>
          <li className="TermsOfUse__list-item">
            To be eligible for a refund, you must request it within 30 days of your initial purchase.
          </li>
        </ul>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">2. Refund Process:</h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">
            To request a refund, please contact our customer support team at:
            <p className="TermsOfUse__text">phone number: +8617664075341</p>
            <p className="TermsOfUse__text">support email: bussiness@tosoiot.com</p>
          </li>
          <li className="TermsOfUse__list-item">Provide your order details and reason for the refund request.</li>
          <li className="TermsOfUse__list-item">
            Our team will review your request and respond to you within 3 business days. Refunds will be credited to
            bank account via the original payment method within 7-15 days of approval.
          </li>
        </ul>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">3. Refund Conditions:</h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">
            Refunds are only available for the initial purchase of our SaaS product.
          </li>
          <li className="TermsOfUse__list-item">
            Any additional purchases, upgrades, or subscription renewals are not eligible for a refund.
          </li>
          <li className="TermsOfUse__list-item">
            Refunds will be issued using the same payment method used for the original purchase.
          </li>
        </ul>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">4. Refund Exceptions:</h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">
            We do not provide refunds for any fees paid to third-party services or platforms integrated with our SaaS
            product.
          </li>
          <li className="TermsOfUse__list-item">
            Refunds will not be granted if there is evidence of misuse, abuse, or violation of our terms of service.
          </li>
        </ul>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">5. Cancellation of Subscription:</h2>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">
            If you decide to cancel your subscription after the initial 30-day refund period, you will not be eligible
            for a refund.
          </li>
          <li className="TermsOfUse__list-item">
            However, you can cancel your subscription at any time, and it will not be renewed automatically.
          </li>
        </ul>
        <p className="TermsOfUse__text">
          Please note that this Return and Refund Policy is subject to change without prior notice. We recommend
          reviewing it periodically.
        </p>
        <p className="TermsOfUse__text">
          If you have any questions or need further assistance, please don't hesitate to contact our customer support
          team.
        </p>
        <p className="TermsOfUse__text">phone number: +8617664075341</p>
        <p className="TermsOfUse__text">support email: bussiness@tosoiot.com</p>
      </section> */}
    </Container>
  );
};

export default ReturnPolicy;
