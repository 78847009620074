/* eslint-disable sort-keys */

const zIndex = {
  webmap: 0,
  projectHeader: 1,
  breadcrumb: 2,
  goToTop: 2,
  layout: 2,
  modal: 200,
  slideupOverlay: 2,
  slideup: 2,
  topNavMobileDropdown: 3,
  topNav: 4,
  topNavMobileButton: 4,
  slideupRoot: 150,
  popoverRoot: 200,
  appsHeroButton: 1,
};

export default zIndex;
