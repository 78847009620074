import React, {FC} from 'react';

import Logo from 'assets/logos/xiangji_logo.png';
import * as S from './Styles';

const TopNavLogo: FC = () => (
  <S.LinkContainer to="/">
    <S.Image alt="thenewboston Logo" src={Logo} />
  </S.LinkContainer>
);

export default TopNavLogo;
