import React, {FC} from 'react';

import {Container} from 'components';

import './TermsOfUse.scss';

const TermsOfUse: FC = () => {
  return (
    <Container className="TermsOfUse" maxWidth={720}>
      {/*
      <div className="TermsOfUse__main-text" />
      <h1 className="TermsOfUse__heading">Terms of service</h1>
      <p className="TermsOfUse__last-updated">Last updated: 11/26/2023</p>
      <p className="TermsOfUse__last-updated">
        These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and
        construed in accordance with the laws of China. Please read these terms and conditions carefully before using
        Our Service.
      </p>
      <div className="TermsOfUse__main-text" />
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">Interpretation and Definitions</h2>
        <p className="TermsOfUse__text">
          Interpretation The words of which the initial letter is capitalized have meanings defined under the following
          conditions. The following definitions shall have the same meaning regardless of whether they appear in
          singular or in plural. Definitions For the purposes of these Terms of Service:
          <ul className="PrivacyPolicy__list">
            <li className="PrivacyPolicy__list-item">
              "Affiliate" means an entity that controls, is controlled by or is under common control with a party,
              "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to
              vote for election of directors or other managing authority.
            </li>
            <li className="PrivacyPolicy__list-item">
              "Account" means a unique account created for You to access our Service or parts of our Service.
            </li>
            <li className="PrivacyPolicy__list-item">
              "Company" (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to (Suzhou
              XiangJi Technology Service Co. Ltd).{' '}
            </li>
            <li className="PrivacyPolicy__list-item">- "Country" refers to China. </li>
            <li className="PrivacyPolicy__list-item">
              "Content" refers to content such as text, images, or other information that can be posted, uploaded,
              linked to or otherwise made available by You, regardless of the form of that content.{' '}
            </li>
            <li className="PrivacyPolicy__list-item">
              "Device" means any device that can access the Service such as a computer, a cell phone or a digital
              tablet.{' '}
            </li>
            <li className="PrivacyPolicy__list-item">
              "Feedback" means feedback, innovations or suggestions sent by You regarding the attributes, performance
              features of our Service.{' '}
            </li>
            <li className="PrivacyPolicy__list-item">- "Service" refers to the Website. </li>
            <li className="PrivacyPolicy__list-item">
              "Terms of Service" (also referred as "Terms") mean these Terms of Service that form the entire agreement
              between You and the Company regarding the use of the Service. This Terms of Service Agreement was
              generated by TermsFeed Terms of Service Generator.{' '}
            </li>
            <li className="PrivacyPolicy__list-item">
              "Third-party Social Media Service" means any services or content (including data, information, products
              services) provided by a third-party that may be displayed, included or made available by the Service.{' '}
            </li>
            <li className="PrivacyPolicy__list-item">
              "Website" refers to XiangJi, accessible from https://www.xiangjiai.com{' '}
            </li>
            <li className="PrivacyPolicy__list-item">
              "You" means the individual accessing or using the Service, or the company, or other legal entity on behalf
              of which such individual is accessing or using the Service, as applicable.
            </li>
          </ul>
        </p>

        <h2 className="TermsOfUse__section-heading">Acknowledgment</h2>
        <p className="TermsOfUse__text">
          These are the Terms of Service governing the use of this Service and the agreement that operates between You
          and the Company. These Terms of Service set out the rights and obligations of all users regarding the use of
          the Service. Your access to and use of the Service is conditioned on Your acceptance of and compliance with
          these Terms of Service. These Terms of Service apply to all visitors, users and others who access or use the
          Service. By accessing or using the Service You agree to be bound by these Terms of Service. If You disagree
          with any part of these Terms of Service then You may not access the Service. You represent that you are over
          the age of 18. The Company does not permit those under 18 to use the Service. Your access to and use of the
          Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our
          Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal
          information when You use the Application or the Website and tells You about Your privacy rights and how the
          law protects You. Please read Our Privacy Policy carefully before using Our Service.
        </p>

        <h2 className="TermsOfUse__section-heading">User Accounts</h2>
        <p className="TermsOfUse__text">
          When You create an account with Us, You must provide Us information that is accurate, complete, and current at
          all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of
          Your account on Our Service. You are responsible for safeguarding the password that You use to access the
          Service and for any activities or actions under Your password, whether Your password is with Our Service or a
          Third-Party Social Media Service. You agree not to disclose Your password to any third party. You must notify
          Us immediately upon becoming aware of any breach of security or unauthorized use of Your account. You may not
          use as a username the name of another person or entity or that is not lawfully available for use, a name or
          trademark that is subject to any rights of another person or entity other than You without appropriate
          authorization, or a name that is otherwise offensive, vulgar or obscene. Content Your Right to Post Content
          Our Service allows You to post Content. You are responsible for the Content that You post to the Service,
          including its legality, reliability, and appropriateness. By posting Content to the Service, You grant Us the
          right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content
          on and through the Service. You retain any and all of Your rights to any Content You submit, post or display
          on or through the Service and You are responsible for protecting those rights. You agree that this license
          includes the right for Us to make Your Content available to other users of the Service, who may also use Your
          Content subject to these Terms. You represent and warrant that: (i) the Content is Yours (You own it) or You
          have the right to use it and grant Us the rights and license as provided in these Terms, and (ii) the posting
          of Your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights,
          contract rights or any other rights of any person. Content Restrictions The Company is not responsible for the
          content of the Service's users. You expressly understand and agree that You are solely responsible for the
          Content and for all activity that occurs under your account, whether done so by You or any third person using
          Your account. You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust,
          threatening, libelous, defamatory, obscene or otherwise objectionable. Examples of such objectionable Content
          include, but are not limited to, the following:
          <ul className="PrivacyPolicy__list">
            <li className="PrivacyPolicy__list-item">- Unlawful or promoting unlawful activity. </li>
            <li className="PrivacyPolicy__list-item">
              Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion,
              race, sexual orientation, gender, national/ethnic origin, or other targeted groups.{' '}
            </li>
            <li className="PrivacyPolicy__list-item">
              Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising, chain
              letters, any other form of unauthorized solicitation, or any form of lottery or gambling.{' '}
            </li>
            <li className="PrivacyPolicy__list-item">
              Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or
              intended to disrupt, damage, or limit the functioning of any software, hardware or telecommunications
              equipment or to damage or obtain unauthorized access to any data or other information of a third person.{' '}
            </li>
            <li className="PrivacyPolicy__list-item">
              Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright,
              right of publicity or other rights.{' '}
            </li>
            <li className="PrivacyPolicy__list-item">
              Impersonating any person or entity including the Company and its employees or representatives.{' '}
            </li>
            <li className="PrivacyPolicy__list-item">- Violating the privacy of any third person. </li>
            <li className="PrivacyPolicy__list-item">- False information and features. </li>
          </ul>
          The Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or not
          any Content is appropriate and complies with this Terms, refuse or remove this Content. The Company further
          reserves the right to make formatting and edits and change the manner of any Content. The Company can also
          limit or revoke the use of the Service if You post such objectionable Content. As the Company cannot control
          all content posted by users and/or third parties on the Service, you agree to use the Service at your own
          risk. You understand that by using the Service You may be exposed to content that You may find offensive,
          indecent, incorrect or objectionable, and You agree that under no circumstances will the Company be liable in
          any way for any content, including any errors or omissions in any content, or any loss or damage of any kind
          incurred as a result of your use of any content. Content Backups Although regular backups of Content are
          performed, the Company does not guarantee there will be no loss or corruption of data. Corrupt or invalid
          backup points may be caused by, without limitation, Content that is corrupted prior to being backed up or that
          changes during the time a backup is performed. The Company will provide support and attempt to troubleshoot
          any known or discovered issues that may affect the backups of Content. But You acknowledge that the Company
          has no liability related to the integrity of Content or the failure to successfully restore Content to a
          usable state. You agree to maintain a complete and accurate copy of any Content in a location independent of
          the Service.
        </p>

        <h2 className="TermsOfUse__section-heading">Copyright Policy</h2>
        <p className="TermsOfUse__text">
          Intellectual Property Infringement We respect the intellectual property rights of others. It is Our policy to
          respond to any claim that Content posted on the Service infringes a copyright or other intellectual property
          infringement of any person. If You are a copyright owner, or authorized on behalf of one, and You believe that
          the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place
          through the Service, You must submit Your notice in writing to the attention of our copyright agent via email
          (bussiness@tosoiot.com) and include in Your notice a detailed description of the alleged infringement. You may
          be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is
          infringing Your copyright. DMCA Notice and DMCA Procedure for Copyright Infringement Claims You may submit a
          notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the
          following information in writing (see 17 U.S.C 512(c)(3) for further detail): - An electronic or physical
          signature of the person authorized to act on behalf of the owner of the copyright's interest. - A description
          of the copyrighted work that You claim has been infringed, including the URL (i.e., web page address) of the
          location where the copyrighted work exists or a copy of the copyrighted work. - Identification of the URL or
          other specific location on the Service where the material that You claim is infringing is located. - Your
          address, telephone number, and email address. - A statement by You that You have a good faith belief that the
          disputed use is not authorized by the copyright owner, its agent, or the law. - A statement by You, made under
          penalty of perjury, that the above information in Your notice is accurate and that You are the copyright owner
          or authorized to act on the copyright owner's behalf. You can contact our copyright agent via email
          (bussiness@tosoiot.com). Upon receipt of a notification, the Company will take whatever action, in its sole
          discretion, it deems appropriate, including removal of the challenged content from the Service.
        </p>

        <h2 className="TermsOfUse__section-heading">Intellectual Property</h2>
        <p className="TermsOfUse__last-updated">
          The Service and its original content (excluding Content provided by You or other users), features and
          functionality are and will remain the exclusive property of the Company and its licensors. The Service is
          protected by copyright, trademark, and other laws of both the Country and foreign countries. Our trademarks
          and trade dress may not be used in connection with any product or service without the prior written consent of
          the Company. Your Feedback to Us You assign all rights, title and interest in any Feedback You provide the
          Company. If for any reason such assignment is ineffective, You agree to grant the Company a non-exclusive,
          perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license,
          distribute, modify and exploit such Feedback without restriction. Links to Other Websites Our Service may
          contain links to third-party web sites or services that are not owned or controlled by the Company. The
          Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of
          any third party web sites or services. You further acknowledge and agree that the Company shall not be
          responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in
          connection with the use of or reliance on any such content, goods or services available on or through any such
          web sites or services. We strongly advise You to read the terms and conditions and privacy policies of any
          third-party web sites or services that You visit.
        </p>
        <h2 className="TermsOfUse__section-heading">Termination</h2>
        <p className="TermsOfUse__last-updated">
          We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason
          whatsoever, including without limitation if You breach these Terms of Service. Upon termination, Your right to
          use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue
          using the Service.
        </p>
        <h2 className="TermsOfUse__section-heading">Limitation of Liability</h2>
        <p className="TermsOfUse__last-updated">
          Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers
          under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the
          amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the
          Service. To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be
          liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited
          to, damages for loss of profits, loss of data or other information, for business interruption, for personal
          injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service,
          third-party software and/or third-party hardware used with the Service, or otherwise in connection with any
          provision of this Terms), even if the Company or any supplier has been advised of the possibility of such
          damages and even if the remedy fails of its essential purpose. Some states do not allow the exclusion of
          implied warranties or limitation of liability for incidental or consequential damages, which means that some
          of the above limitations may not apply. In these states, each party's liability will be limited to the
          greatest extent permitted by law.
        </p>
        <h2 className="TermsOfUse__section-heading">"AS IS" and "AS AVAILABLE" Disclaimer</h2>
        <p className="TermsOfUse__last-updated">
          The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of
          any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf
          of its Affiliates and its and their respective licensors and service providers, expressly disclaims all
          warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all
          implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and
          warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without
          limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of
          any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work
          with any other software, applications, systems or services, operate without interruption, meet any performance
          or reliability standards or be error free or that any errors or defects can or will be corrected. Without
          limiting the foregoing, neither the Company nor any of the company's provider makes any representation or
          warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the
          information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted
          or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided
          through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of
          the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful
          components. Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on
          applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not
          apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to
          the greatest extent enforceable under applicable law. Governing Law The laws of the Country, excluding its
          conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may
          also be subject to other local, state, national, or international laws.
        </p>

        <h2 className="TermsOfUse__section-heading">Disputes Resolution</h2>
        <p className="TermsOfUse__last-updated">
          If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally
          by contacting the Company. For European Union (EU) Users If You are a European Union consumer, you will
          benefit from any mandatory provisions of the law of the country in which you are resident in. United States
          Legal Compliance You represent and warrant that (i) You are not located in a country that is subject to the
          United States government embargo, or that has been designated by the United States government as a "terrorist
          supporting" country, and (ii) You are not listed on any United States government list of prohibited or
          restricted parties. Severability and Waiver Severability If any provision of these Terms is held to be
          unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such
          provision to the greatest extent possible under applicable law and the remaining provisions will continue in
          full force and effect. Waiver Except as provided herein, the failure to exercise a right or to require
          performance of an obligation under these Terms shall not effect a party's ability to exercise such right or
          require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any
          subsequent breach. Changes to These Terms of Service We reserve the right, at Our sole discretion, to modify
          or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at
          least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be
          determined at Our sole discretion. By continuing to access or use Our Service after those revisions become
          effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in
          part, please stop using the website and the Service. Contact Us If you have any questions about these Terms of
          Service, You can contact us:
          <li className="PrivacyPolicy__list-item">
            By visiting this page on our website: https://www.xiangjiai.com/home/contact
          </li>
          <li className="PrivacyPolicy__list-item">By sending us an email: bussiness@tosoiot.com</li>
        </p>
      </section>
      */}
      <p className="TermsOfUse__effective">生效时间: 01-01-2021</p>
      <p className="TermsOfUse__last-updated">上次更改时间: 11-28-2022</p>
      <h1 className="TermsOfUse__heading">使用须知</h1>
      <div className="TermsOfUse__main-text" />
      <p className="TermsOfUse__text">
        本服务条款以及我们向您提供服务的任何单独协议均应受中国法律管辖并根据中国法律解释。在使用我们的服务之前，请仔细阅读这些条款和条件。
      </p>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">一、条款的范围与确认</h2>
        <p className="TermsOfUse__text">
          本条款中的"产品服务"指象寄科技有限公司向用户提供的象寄科技(https://www.xiangjiai.com)上所展示的服务功能，具体服务内容以您所使用或订购的内容为准。
          本条款有助于您了解本平台的服务内容及用户的使用权利、义务，请您仔细阅读本条款（特别是以粗体标注的内容）。在接受本条款之前，请您仔细阅读全部内容，如您无法准确理解或不同意本条款的任一内容，请不要进行后续操作。
          通过网络页面点击确认、实际购买、使用我们提供的服务等操作，或以其他方式选择接受本条款的，即表示您同意并接受本条款的全部内容。
          由于互联网行业的高速发展，本条款列明的条款未必能完整罗列并覆盖您与本平台的所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，本平台有权在后续运营中对本条款做任何修改和补充，并将在修改补充后再次将其公示以便获得您的确认。对于服务条款、隐私政策或规则的修改将在公布时或规定的生效日立即生效。
          若在新的条款生效后访问和使用本平台产品服务，视为您同意接受修订后的服务条款。若您不同意变更修改后的条款内容，您有权停止使用相关服务、删除您在象寄科技上有关账户信息中的内容等方式来终止服务条款。
          如果您代表某个机构而非您个人注册、登录及使用本平台产品服务，则您将被认为已获得充分授权代表该机构同意本条款以及本条款后续的修改和补充。
        </p>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">二、账号注册与安全</h2>
        <p className="TermsOfUse__text">
          您在使用本平台产品服务前需要注册本平台帐号。您须对注册信息的真实性、合法性、有效性承担全部责任，并配合我们依法核验您的注册信息；您不得未经授权代表他人或冒充他人；您也不得利用他人的名义发布任何信息或恶意使用注册帐号导致其他用户误认。
        </p>
        <p className="TermsOfUse__text">
          您有责任妥善保管注册帐号及密码信息的安全，您需要对注册帐号项下的行为承担法律责任。因您的原因造成的账户、密码等信息被冒用、盗用或非法使用，由此引起的一切风险、责任、损失、费用等应由您自行承担。
        </p>
        <p className="TermsOfUse__text">
          您在注册帐号中设置的用户名、头像、个人介绍等必须遵守法律法规、公序良俗、社会公德，且不会侵害其他第三方的合法权益，否则我们可能会取消您的用户名、头像、个人介绍。
        </p>
        <p className="TermsOfUse__text">
          如您发现有他人冒用或盗用您的帐号，请您立即以有效方式通知我们，您也可以向我们申请暂停或终止本平台产品服务。
        </p>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">三、用户使用相关规则</h2>
        <p className="TermsOfUse__text">您承诺不得使用本平台服务、从事以下行为，包括但不限于：</p>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">
            发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽、暴力的内容；
          </li>
          <li className="TermsOfUse__list-item">
            发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；
          </li>
          <li className="TermsOfUse__list-item">虚构事实、隐瞒真相以误导、欺骗他人；</li>
          <li className="TermsOfUse__list-item">发表、传送、传播广告信息及垃圾信息；</li>
          <li className="TermsOfUse__list-item">其他违反法律法规、政策及公序良俗或侵犯第三方合法权益的行为。</li>
        </ul>
        <p className="TermsOfUse__text">您承诺发送或传播的内容应有合法来源，相关内容为您所有或您已获得权利人的授权。</p>
        <p className="TermsOfUse__text">
          您不得使用未经授权的插件、外挂或第三方工具对本条款项下的服务进行干扰、破坏、修改或施加其他影响。
        </p>
        <p className="TermsOfUse__text">
          您不得对本平台产品系统和程序采取反向工程手段进行破解，不得对上述系统和程序（包括但不限于源程序、目标程序、技术文档、服务器数据）进行复制、修改、编译、整合或篡改，不得修改或增减本平台产品系统的任何功能，如有违反，本平台将保留依法追究相关责任者一切法律责任的权利。
        </p>
        <p className="TermsOfUse__text">
          如您违反法律法规或本条款、补充条款，我们将作出独立判断立即暂停或终止您的服务帐号。您需自行对其全部行为承担法律责任，我们不承担任何法律责任。相关国家机关或机构、我们、第三方可能会对您提起诉讼、罚款或采取其他制裁措施，您应独立承担相关责任，造成损害的，您应依法予以赔偿。如因此导致我们遭受损失的，您也应当一并赔偿。
        </p>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">四、内容相关规则</h2>
        <p className="TermsOfUse__text">
          您发布的资源内容（包括但不限于网页、文字、图片、音频、视频、图表等）必须是您自己创作或您有权许可他人使用的作品。请确保您发布的资源内容不会侵犯任何第三方权益，包括但不限于任何知识产权或隐私权。
          您在本平台上发布"原型作品"、"设计作品"等原创资源内容，著作权本平台将均视为归您本人所有。您可授权第三方以任何方式使用，不需要得到我们的同意。如您的作品中有他人授权的作品内容或含有临摹仿照他人作品内容的部分，作为内容的发布者，您需自行对所发布的内容负责，引发的一切纠纷，由您自行承担法律责任。
          为了保障您的合法权益，建议您不要发布任何不希望别人使用的资源内容。如果您对使用者有特殊的限制，请在发布资源时，在内容或描述中标明您允许的使用方式，以供其他使用者在使用时进行参考。平台在您发布您的资源内容后，平台将允许其他用户对该资源内容进行查看、复制、编辑和保存在内的一系列使用行为；但平台无法对使用该资源内容的其他用户进行使用方式和使用范围及用途上的限制。
        </p>
        <p className="TermsOfUse__text">
          为了促进您创作内容的分享和传播，您通过本平台发表的公开资源，我们将视为您授予我们免费的、全球的、不可撤销的非独家使用许可，我们有权将内容用于各种形态开发、运营产品和服务上。
          如果任何第三方侵犯了您在本平台的相关权利，您同意授权本平台或其指定的代理人代表您本人或本平台对该第三方提出警告、投诉、发起行政执法、诉讼、进行上诉（但非义务），或谈判和解，并且您同意在本平台认为必要的情况下参与共同维权。
          如我们发现或收到他人举报您发布的内容违反法律法规或本协议约定的，我们有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时有权视您的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止帐号使用，追究法律责任等措施。
        </p>
        <p className="TermsOfUse__text">
          在没有获得著作权人或其代理人的许可之前，您不能使用平台上的资源内容，也不能通过"右键--保存"、截屏或其他技术方式复制和保存他人作品。
          平台功能服务中出现的示例图片、资源内容以及内容中含有的字体、商标、肖像等其他权利，为第三方所有，未授权平台用户使用。我们不是授予您许可的一方，也不是此许可的一方，任何商业用途均须联系著作权人或其代理人获取授权，按照获取的许可进行使用。
          某些资源中可能包含原作者在其他途径获取的素材（包括但不限于字体、图片、组件），并且这些素材可能存在不同的许可协议。建议您在使用之前，通过阅读资源内容、描述页中标识说明或与联系作者了解具体情况。如因为使用平台上其他用户发布的资源内容而给您带来的纠纷、赔偿及其他一系列的法律问题，均由您和相关第三方自行解决；平台在提供告知义务后，不承担任何相关的法律责任，亦不承担其他任何的保证或保障。{' '}
        </p>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">五、知识产权声明</h2>
        <p className="TermsOfUse__text">
          我司所有系统及本网站上所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计，均由我司依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。
          尊重知识产权是您应尽的义务，如有违反，您应承担相应的法律责任和赔偿。{' '}
        </p>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">六、收费服务</h2>
        <p className="TermsOfUse__text">
          如您使用本平台的收费服务项目，请遵守相关的条款并按期足额支付费用。
          我们可能根据实际需要对收费服务的收费标准、方式进行修改和变更，我们也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，我们将在相应服务页面进行通知或公告。如您不同意上述修改、变更或付费内容，则应停止使用该服务。
        </p>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">七、不可抗力与其他免责事由</h2>
        <p className="TermsOfUse__text">
          由于不可抗力事件导致用户遭受损失的，我们不承担责任。不可抗力事件包括但不限于：
          <ul className="TermsOfUse__list">
            <li className="TermsOfUse__list-item">自然灾害、如台风、洪水、冰雹；</li>
            <li className="TermsOfUse__list-item">政府行为，如征收、征用；</li>
            <li className="TermsOfUse__list-item"> 社会异常事件，如罢工、骚乱；</li>
            <li className="TermsOfUse__list-item"> 计算机病毒或黑客攻击、互联网络、通信线路等原因造成的服务中断。</li>
          </ul>
        </p>
        <p className="TermsOfUse__text">
          您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，我们不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。这些风险包括但不限于：
        </p>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；</li>
          <li className="TermsOfUse__list-item">
            因使用本条款项下的服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；
          </li>
          <li className="TermsOfUse__list-item"> 其他因网络信息或用户行为引起的风险。</li>
        </ul>

        <p className="TermsOfUse__text">
          平台服务中可能会包含指向第三方网站所控制的其他事件或活动链接。我们不对任何此类第三方网站提供的信息、产品、资源、服务表示认可或承担任何责任。如果您通过服务访问、使用第三方网站提供服务或内容而引起的任何责任，由您自行承担后果，我们不承担任何责任。
        </p>
        <p className="TermsOfUse__text">
          您理解并同意，我们对于与本条款有关或由本条款引起的任何间接的、惩罚性的、特殊的、派生的损失（包括但不限于业务损失、收益损失、利润损失、商誉或其他经济利益的损失），不论是如何产生的，也不论是由对本条款的违约还是由侵权造成的，均不承担责任，即使您已被告知该等损失的可能性。
        </p>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">八、服务的变更、中断、终止</h2>
        <p className="TermsOfUse__text">
          为了改善用户体验、完善服务内容，保证本平台产品服务的安全性和功能的一致性，我们可能随时对本平台产品服务或相关功能、相关应用软件进行更新、功能强化、升级、修改或转移。
          如因自然灾害,
          其他信息服务商故障，相关信息设备和软件检修、维护、故障或者任何不可阻挡因素导致服务中断或终止，不视为我们违约，但我们应尽可能减少该因素对用户的影响。如发生下列任何一种情形，我们有权中断或终止向您提供的服务：
        </p>
        <ul className="TermsOfUse__list">
          <li className="TermsOfUse__list-item">
            根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
          </li>
          <li className="TermsOfUse__list-item">您违反相关法律法规或本条款、补充条款的约定；</li>
          <li className="TermsOfUse__list-item"> 按照法律规定或主管部门的要求；</li>
          <li className="TermsOfUse__list-item"> 我们提前以公告或其他书面方式通知您终止本服务；</li>
          <li className="TermsOfUse__list-item"> 出于安全的原因或其他必要的情形。</li>
        </ul>
        <p className="TermsOfUse__text">
          如您的服务因包括但不限于上述原因被终止，我们可以从服务器上永久地删除您的数据，但法律法规另有规定的除外。服务终止后，我们没有义务向您返还数据。
        </p>
      </section>
      <section className="TermsOfUse__section">
        <h2 className="TermsOfUse__section-heading">法律适用、管辖与其他</h2>
        <p className="TermsOfUse__text">
          本条款之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国法律；如法律无相关规定的，参照商业惯例及/或行业惯例。
          您因使用本平台产品服务所产生的相关的争议，由我们与您协商解决。协商不成时，任何一方均可向平台经营所在地有管辖权的人民法院提起诉讼。
          本条款任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本条款其余条款的有效性及可执行性。
        </p>
      </section>
    </Container>
  );
};

export default TermsOfUse;
