import React, {FC} from 'react';

import {PageTitle} from 'components';
import FirstView from './FirstView';
import Introduction from './Introduction';
import ImageEditor from './ImageEditor';
// import VideoTrans1 from './VideoTrans1';
import VideoTrans2 from './VideoTrans2';
// import OverallProcess from './OverallProcess';
import Brush from './Brush';
// import WaterMark from './WaterMark';
import Links from './Links';
// import TranslatePerformance from './TranslatePerformance';
import './style.scss';

const Home: FC = () => (
  <>
    <PageTitle title="象寄翻译" />
    <div className="hero">
      <FirstView />
      <Links />
    </div>
    <Introduction />
    {/* <TranslatePerformance /> */}
    {/* <Overall /> */}
    {/* <VideoTrans1 /> */}
    <VideoTrans2 />
    {/* <OverallProcess /> */}
    <ImageEditor />
    <Brush />

    {/* <Brush /> */}

    {/* <WaterMark /> */}
    {/* <ImageEditor /> */}
    {/* <Links /> */}
  </>
);

export default Home;
