import React, {FC} from 'react';

const VideoTrans: FC = () => {
  return (
    <img
      id="vidoeTransLogo"
      alt="视频翻译"
      width="160"
      height="160"
      src="https://i.tosoiot.com/static/images/videoTrans.png"
    />
  );
};

export default VideoTrans;
