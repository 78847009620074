import React, {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
// import {getLogStatus} from 'apis/apps';
// import {useHistory} from 'react-router';

import {isCreateAccountAllowed, isSignInAllowed} from 'config';
import {ROUTES, URLS} from 'constants/routes';
import TopNavLink from 'containers/TopNav/TopNavLink';
import TopNavPopover, {TopNavPopoverItemType} from 'containers/TopNav/TopNavPopover';
import {selectActiveUser} from 'selectors/state';
import colors from 'styles/colors';
// import {setUser} from 'store/models';
import {developerPopoverItems, getProductPopoverItems, resourcesPopoverItems, getProxyPopoverItems} from './constants';
// import TnbLogo from '../../../assets/svgs/TnbLogo';
import DiscordLogo from '../../../assets/svgs/DiscordLogo';

import * as S from './Styles';

const TopNavDesktopItems = () => {
  // const history = useHistory();
  const activeUser = useSelector(selectActiveUser);
  const [activeUserAnchorEl, setActiveUserAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [developersAnchorEl, setDevelopersAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [getProductAnchorEl, setGetProductAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [resourcesAnchorEl, setResourcesAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [proxyAnchorEl, setProxyAnchorEl] = useState<HTMLButtonElement | null>(null);
  // const [isLogged, setIsLogged] = useState(false);

  // const [isAppsButtonHovered, setIsAppsButtonHovered] = useState(false);
  const [isDiscordButtonHovered, setIsDiscordButtonHovered] = useState(false);

  const activeUserPopoverItems = useMemo<TopNavPopoverItemType[]>(() => {
    if (activeUser) {
      return [
        {title: 'Your Profile', to: `${ROUTES.users}/${activeUser.pk}`},
        {title: 'Sign Out', to: ROUTES.signout},
      ];
    }
    return [];
  }, [activeUser]);

  // useEffect(() => {
  //   (async function getUserStatus() {
  //     try {
  //       // setProgress(ApiProgress.Requesting);
  //       const logStatusResponse = await getLogStatus();
  //       if (logStatusResponse.code !== '1003') {
  //         setIsLogged(true);
  //       }

  //       // set(appResponse);
  //       // setProgress(ApiProgress.Success);
  //     } catch (err) {
  //       // setProgress(ApiProgress.Error);
  //     }
  //   })();
  // }, []);

  const renderActiveUser = () => {
    if (!activeUser) return null;
    const {profile_image: profileImage} = activeUser;
    return (
      <TopNavPopover
        className="TopNavDesktopItems__profile-image"
        anchorEl={activeUserAnchorEl}
        customButtonContent={<S.ProfileImage src={profileImage} size={36} />}
        items={activeUserPopoverItems}
        popoverId="active-user-popover"
        setAnchorEl={setActiveUserAnchorEl}
      />
    );
  };

  const renderAuthButtons = () => {
    if (activeUser) return null;
    return (
      <>
        {isCreateAccountAllowed && (
          <TopNavLink className="TopNavDesktopItems__right-item" text="Create Account" to={ROUTES.createAccount} />
        )}
        {isSignInAllowed && <TopNavLink className="TopNavDesktopItems__right-item" text="Sign In" to={ROUTES.signin} />}
      </>
    );
  };

  return (
    <S.Container>
      <TopNavPopover
        anchorEl={getProductAnchorEl}
        buttonText="产品服务"
        items={getProductPopoverItems}
        popoverId="get-product-popover"
        setAnchorEl={setGetProductAnchorEl}
      />
      <TopNavPopover
        anchorEl={developersAnchorEl}
        buttonText="API对接"
        items={developerPopoverItems}
        popoverId="developer-popover"
        setAnchorEl={setDevelopersAnchorEl}
      />
      <TopNavPopover
        anchorEl={proxyAnchorEl}
        buttonText="代理与返现"
        items={getProxyPopoverItems}
        popoverId="proxy-popover"
        setAnchorEl={setProxyAnchorEl}
      />
      <TopNavPopover
        anchorEl={resourcesAnchorEl}
        buttonText="联系我们"
        items={resourcesPopoverItems}
        popoverId="resources-popover"
        setAnchorEl={setResourcesAnchorEl}
      />
      <S.Separator />
      {renderAuthButtons()}
      <S.RightSection>
        {/* <S.DownloadButton onClick={() => history.push(ROUTES.download)} variant="outlined">
          代理商入口
        </S.DownloadButton> */}
        {/* {isLogged ? (
          <S.DiscordButton
            onClick={() => {
              window.location.href = URLS.login;
            }}
            onMouseEnter={() => setIsDiscordButtonHovered(true)}
            onMouseLeave={() => setIsDiscordButtonHovered(false)}
          >
            <DiscordLogo
              color={isDiscordButtonHovered ? colors.discordHover : colors.discord}
              style={{marginRight: '8px'}}
            />
            登录/注册
          </S.DiscordButton>
        ) : (
          <div> */}

        <S.DiscordButton
          onClick={() => {
            window.location.href = URLS.login;
          }}
          onMouseEnter={() => setIsDiscordButtonHovered(true)}
          onMouseLeave={() => setIsDiscordButtonHovered(false)}
        >
          <DiscordLogo
            color={isDiscordButtonHovered ? colors.discordHover : colors.discord}
            style={{marginRight: '8px'}}
          />
          {/* {userName}控制台 */}
          开始使用
        </S.DiscordButton>
        {/* </div> */}
        {/* )} */}

        {/* <S.AppButton
          onClick={() => history.push(ROUTES.apps)}
          onMouseEnter={() => setIsAppsButtonHovered(true)}
          onMouseLeave={() => setIsAppsButtonHovered(false)}
        >
          <TnbLogo color={isAppsButtonHovered ? colors.primary : colors.white} style={{marginRight: '8px'}} />
          登录/注册
        </S.AppButton> */}
      </S.RightSection>
      {renderActiveUser()}
    </S.Container>
  );
};

export default TopNavDesktopItems;
